import { AppAction } from 'types';
import {
  GET_LATEST_SAFETY_REVIEW_DATA_REPORT_DETAILS_SUCCEEDED,
  GET_ARCHIVED_SAFETY_REVIEW_DATA_REPORT_DETAILS_SUCCEEDED,
  CLEAR_SAFETY_REVIEW_DATA_REPORT_DETAILS,
  GET_LATEST_FULL_DATA_EXTRACT_REPORT_DETAILS_SUCCEEDED,
  GET_ARCHIVED_FULL_DATA_EXTRACT_REPORT_DETAILS_SUCCEEDED,
  CLEAR_REPORTS_DATA_DETAILS,
  GET_REPORTS_AUDIT_TRAILS_DATA_SUCCEEDED,
} from './constants';
import { ReportDetailType } from './types';

const SafetyReviewDataInitial = {
  latest: {
    id: null,
    name: null,
    generatedOn: null,
  },
  archived: [
    {
      id: null,
      name: null,
      generatedOn: null,
    },
  ],
  pagination: {
    endNumber: null,
    firstPage: null,
    lastPage: null,
    page: null,
    prevPage: null,
    startNumber: null,
    total: null,
    totalPages: null,
  },
};

const FullDataExtractInitial = {
  latest: {
    id: null,
    name: null,
    generatedOn: null,
  },
  archived: [
    {
      id: null,
      name: null,
      generatedOn: null,
    },
  ],
  pagination: {
    endNumber: null,
    firstPage: null,
    lastPage: null,
    page: null,
    prevPage: null,
    startNumber: null,
    total: null,
    totalPages: null,
  },
};

const dataReportInitialData: ReportDetailType = {
  safetyReviewData: SafetyReviewDataInitial,
  fullDataExtract: FullDataExtractInitial,
  auditTrails: [],
  auditTrailsHeaders: [],
};

const DataReportsReducer = (
  state = dataReportInitialData,
  action: AppAction
) => {
  switch (action.type) {
    case GET_LATEST_SAFETY_REVIEW_DATA_REPORT_DETAILS_SUCCEEDED: {
      const data = action.data as ReportDetailType;
      return {
        ...state,
        safetyReviewData: {
          ...state.safetyReviewData,
          latest: data,
        },
      };
    }
    case GET_ARCHIVED_SAFETY_REVIEW_DATA_REPORT_DETAILS_SUCCEEDED: {
      const { data, pagination } = action.data;
      return {
        ...state,
        safetyReviewData: {
          ...state.safetyReviewData,
          archived: data,
          pagination,
        },
      };
    }
    case CLEAR_SAFETY_REVIEW_DATA_REPORT_DETAILS: {
      return {
        ...state,
        safetyReviewData: SafetyReviewDataInitial,
      };
    }
    case GET_LATEST_FULL_DATA_EXTRACT_REPORT_DETAILS_SUCCEEDED: {
      const data = action.data as ReportDetailType;
      return {
        ...state,
        fullDataExtract: {
          ...state.fullDataExtract,
          latest: data,
        },
      };
    }
    case GET_ARCHIVED_FULL_DATA_EXTRACT_REPORT_DETAILS_SUCCEEDED: {
      const { data, pagination } = action.data;
      return {
        ...state,
        fullDataExtract: {
          ...state.fullDataExtract,
          archived: data,
          pagination,
        },
      };
    }
    case CLEAR_REPORTS_DATA_DETAILS: {
      return {
        ...state,
        [action.data?.tab]:
          action.data?.tab === 'safetyReviewData'
            ? SafetyReviewDataInitial
            : FullDataExtractInitial,
      };
    }
    case GET_REPORTS_AUDIT_TRAILS_DATA_SUCCEEDED: {
      const { auditTrails, auditTrailsHeaders } = action.data;
      return {
        ...state,
        auditTrails,
        auditTrailsHeaders,
      };
    }
    default: {
      return state;
    }
  }
};

export default DataReportsReducer;
