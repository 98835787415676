import React, { useState, FC } from 'react';
import { FormModal } from 'components/StyleGuideComponents/common/modals';
import CheckBox from 'components/StyleGuideComponents/CheckBox';
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Filter, SubFilter } from '../types';
import { setAppliedFilters } from '../actions';

type PaymentFiltersPropTypes = {
  show: boolean;
  modalClose: () => void;
};

const PaymentFilters: FC<PaymentFiltersPropTypes> = props => {
  const { show, modalClose } = props;

  const { filters, appliedFilters } = useAppSelector(
    store => store.paymentsReducer
  );
  const dispatch = useAppDispatch();

  const [selectedFilters, setSelectedFilters] =
    useState<Record<string, SubFilter[]>>(appliedFilters);

  const selectedFiltersCount = Object.values(selectedFilters).reduce(
    (acc, currArray) => acc + currArray.length,
    0
  );

  const onCheckBoxChange = (filterKey: string, subFilter: SubFilter) => {
    setSelectedFilters(previouslySelectedFilters => {
      const currentFilters = previouslySelectedFilters[filterKey] || [];

      const isSelected = currentFilters.some(
        filter => filter.key === subFilter.key
      );

      const newFilters = isSelected
        ? currentFilters.filter(filter => filter.key !== subFilter.key)
        : [...currentFilters, subFilter];

      return {
        ...previouslySelectedFilters,
        [filterKey]: newFilters,
      };
    });
  };

  const renderFilters = () => {
    return (
      <div className='payment-filters'>
        <div className='filters-body'>
          {filters.map((filter: Filter) => (
            <div key={filter.key} className='filter-group'>
              <p className='filter-name'>{filter.name}</p>

              <div className='sub-filter-outer'>
                <div className='sub-filter-group'>
                  {filter.subFilters.map((subFilter: SubFilter) => (
                    <div key={subFilter.key} className='sub-filter'>
                      <CheckBox
                        disabled={false}
                        name={subFilter.key}
                        checked={
                          selectedFilters[filter.key]?.some(
                            selectedSubFilter =>
                              selectedSubFilter.key === subFilter.key
                          ) || false
                        }
                        onChange={() => {
                          onCheckBoxChange(filter.key, subFilter);
                        }}
                        label={subFilter.displayName}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className='filter-buttons'>
          <SecondaryButton style={{ width: '160px' }} onClick={modalClose}>
            Cancel
          </SecondaryButton>
          <div className='right-side-buttons'>
            <TertiaryButton
              style={{
                display: selectedFiltersCount > 0 ? 'block' : 'none',
                width: 'fit-content',
              }}
              onClick={() => {
                setSelectedFilters({});
              }}
            >
              Clear All
            </TertiaryButton>
            <PrimaryButton
              style={{ width: '240px' }}
              onClick={() => {
                dispatch(setAppliedFilters(selectedFilters));
                modalClose();
              }}
            >
              Apply Filters
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <FormModal
      show={show}
      onHide={modalClose}
      title={{
        text: 'Filters',
      }}
      modalStyle={{
        height: '650px',
        width: '700px',
      }}
      body={renderFilters()}
    />
  );
};

export default PaymentFilters;
