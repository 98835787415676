import React from 'react';
import { SecondaryButton } from '../common/buttons';

interface TabProps {
  text: string;
  edgeType: 'left' | 'center' | 'right';
  onClick: (id: number) => void;
  isActive: boolean;
}
const Tab: React.FC<TabProps> = ({
  text,
  edgeType = 'center',
  onClick,
  isActive,
}) => {
  const getStyleClassForEdgeType = (): string => {
    switch (edgeType) {
      case 'left':
        return 'btn-left-tab';
      case 'center':
        return 'btn-center-tab';
      case 'right':
        return 'btn-right-tab';
      default:
        return 'btn-center-tab';
    }
  };
  return (
    <div className='tab-wrapper'>
      <SecondaryButton
        className={`${getStyleClassForEdgeType()}  ${isActive ? 'active' : ''}`}
        style={{ maxWidth: 'none' }}
        onClick={onClick}
      >
        {text}
      </SecondaryButton>
    </div>
  );
};
export default Tab;
