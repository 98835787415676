import React, { useEffect } from 'react';
import { SecondaryButton } from 'components/StyleGuideComponents/common/buttons';
import './index.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { getReportGenerationInfo, triggerReportGenereation } from './actions';

interface ReportsAdminProps {
  isActive: boolean;
}

const ReportsAdmin: React.FC<ReportsAdminProps> = ({ isActive }) => {
  const dispatch = useAppDispatch();
  const { safetyDataReportGeneratedOn, fullDataReportGeneratedOn } =
    useAppSelector(store => store.ReportsAdminReducer);

  useEffect(() => {
    if (isActive) dispatch(getReportGenerationInfo());
  }, [isActive]);

  const generateReport = type => {
    dispatch(
      secureActionWithDoubleAuthentication(triggerReportGenereation, {
        report_type: type,
        needs_reason: true,
        secure_action_code: SecureCodes.GENERATE_DATA_REPORT,
      })
    );
  };

  return (
    <div>
      <div className='report-admin-title'>
        <p>Ad Hoc Report Generation </p>
      </div>
      <div className='reports-admin-wrapper'>
        <SecondaryButton
          className='safety-review-data-btn'
          onClick={() => {
            generateReport('SafetyDataReport');
          }}
        >
          Generate Safety Review Data
        </SecondaryButton>

        <p className='last-generated-msg'>{`Last generated On: ${
          safetyDataReportGeneratedOn || ''
        }`}</p>

        <SecondaryButton
          className='safety-review-data-btn'
          onClick={() => {
            generateReport('FullDataReport');
          }}
        >
          Generate Full Data Extract
        </SecondaryButton>

        <p className='last-generated-msg'>{`Last generated On: ${
          fullDataReportGeneratedOn || ''
        }`}</p>
      </div>
    </div>
  );
};
export default ReportsAdmin;
