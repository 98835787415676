import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import { SingleSelectWithoutBorder as CustomizedSelect } from 'components/SelectV2';
import ConfirmationCard from 'components/ConfirmationCard';
import validate from 'common/validator';
import { useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { ACCESS_CONTROL_KEYS } from 'constants';
import { resetPhaseChangeStarted, resetExitStudyStarted } from 'common/actions';
import {
  exitPatientFromStudyAction,
  updatePhaseAction,
} from '../../containers/PatientDetails/actions';
import { TertiaryButton } from '../StyleGuideComponents/common/buttons';
import { colors } from '../StyleGuideComponents/StyleGuideCSS';
import PatientInfoCard from './ReusableFragments/PatientInfoCard';
import DeviceInterrogation from './components/DeviceInterrogation';
import DeviceInterrogationMultiAuth from './components/DeviceInterrogationMultiAuth';

const StudyInfoCard = () => {
  const currentSubject = useSelector(store => store.currentPatientInfo);
  const phaseChangeStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.phaseChangeStarted
  );
  const exitStudyStarted = useSelector(
    store => store.ModalCloseOnPromiseReducer.exitStudyStarted
  );
  const appFeatures = useSelector(store => store.currentUser.appFeatures);

  const procedure = currentSubject.patient?.userProcedures.length
    ? currentSubject.patient?.userProcedures[0].procedureName
    : null;
  const userProcedure = currentSubject.patient.userProcedures.length
    ? currentSubject.patient.userProcedures[0]
    : null;
  const location = userProcedure ? userProcedure.locationName : 'NA';
  const cohort = currentSubject.patient.cohort
    ? currentSubject.patient.cohort?.label
    : 'NA';
  const [showPhaseModal, setShowPhaseModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confirmationType, setConfirmationType] = useState('Eligibility');
  const dispatch = useDispatch();
  const { stages } = currentSubject;
  const allowedStages =
    stages.allowedTransitions && stages.allowedTransitions.length
      ? stages.allowedTransitions
          .filter(item => item.isAutomatic === false)
          .map(item => ({
            label: `${item.fromStage.showToEducatorAs} => ${item.toStage.showToEducatorAs}`,
            value: item.id,
            key: item.id,
          }))
      : [];
  const currentStage = stages.currentStage
    ? stages.currentStage.showToEducatorAs
    : '';
  const [selectedStage, setSelectedStage] = useState('');
  const [error, setError] = useState({});
  const params = useParams();
  const EXIT_STUDY = 'Exit Study';
  const notMovingForward =
    userProcedure && userProcedure.notMovingForward !== undefined
      ? userProcedure.notMovingForward
      : null;
  const isStageChangeAllowed = appFeatures[ACCESS_CONTROL_KEYS.PHASE_CHANGE]
    .canPerform
    ? allowedStages && allowedStages.length > 0 && !notMovingForward
    : false;
  let selectedReasonToExit = '';
  let additionalComments = '';
  let password = '';

  const exitStudyValidationConfig = {
    fields: ['selectedReasonToExit', 'password'],
    rules: {
      selectedReasonToExit: [
        {
          rule: 'isRequired',
          message: 'At least one reason should be selected',
        },
      ],
      additionalComments: [
        {
          rule: 'isRequired',
          message: 'This selection requires Additional Comment',
        },
      ],
      password: [
        { rule: 'isRequired', message: 'Please enter password to confirm' },
      ],
    },
  };

  const onChangePhase = e => {
    setShowPhaseModal(true);
    setConfirmationType(e.label);
    setSelectedStage(e.key);
    setError({});
  };

  const onClosePhaseModal = () => {
    setShowPhaseModal(false);
    setError({});
    selectedReasonToExit = '';
  };

  useEffect(() => {
    if (phaseChangeStarted) {
      onClosePhaseModal();
      dispatch(resetPhaseChangeStarted());
    }
    if (exitStudyStarted) {
      onClosePhaseModal();
      dispatch(resetExitStudyStarted());
    }
  }, [phaseChangeStarted, exitStudyStarted]);

  const stageChangeValidationConfig = {
    fields: ['allowedStages', 'password'],
    rules: {
      allowedStages: [
        { rule: 'isRequired', message: 'Invalid Stage Transition' },
      ],
      password: [
        { rule: 'isRequired', message: 'Please enter password to confirm' },
      ],
    },
  };

  const onStudyExitClicked = e => {
    e.preventDefault();
    setShowPhaseModal(true);
    setConfirmationType(EXIT_STUDY);
  };

  const onFormValidationFailure = errors => {
    setError(errors);
  };

  const getConditionalValidationForExitStudy = () => {
    let { fields } = exitStudyValidationConfig;
    if (selectedReasonToExit.isAdditionalCommentRequired) {
      fields = [...exitStudyValidationConfig.fields, 'additionalComments'];
    }
    return { fields, rules: exitStudyValidationConfig.rules };
  };

  const stageChangeSuccess = () => {
    const data = {
      procedure_stage_transition_id: selectedStage,
      id: parseInt(params.patientId, 10) || -1,
      user_procedure_id: parseInt(params.procedureId, 10) || -1,
      notes: '',
      current_password: password,
      reason: selectedReasonToExit?.isCommentAllowed
        ? `${selectedReasonToExit?.value}-${additionalComments}`
        : selectedReasonToExit?.value,
    };
    dispatch(updatePhaseAction(data));
    setError({});
  };

  const exitStudySuccess = () => {
    const data = {
      userId: currentSubject.patient.id,
      userProcedureId: userProcedure.id,
      notMovingForward: true,
      notMovingForwardReasonId: selectedReasonToExit?.value,
      notMovingForwardAdditionalComment: additionalComments,
      currentPassword: password,
      reasonAuditTrail: selectedReasonToExit?.isAdditionalCommentRequired
        ? `${selectedReasonToExit?.label}-${additionalComments}`
        : selectedReasonToExit?.label,
    };
    setError({});
    dispatch(exitPatientFromStudyAction(data));
  };

  const validateChangedStage = () => {
    validate(
      stageChangeValidationConfig,
      { allowedStages, password },
      onFormValidationFailure,
      stageChangeSuccess
    );
  };

  const validateExitStudyReason = () => {
    const data = { selectedReasonToExit, password };
    if (selectedReasonToExit.isAdditionalCommentRequired)
      data.additionalComments = additionalComments;
    validate(
      getConditionalValidationForExitStudy(),
      data,
      onFormValidationFailure,
      exitStudySuccess
    );
  };

  const onConfirmingStageChange = (
    selectedReasonToQuit,
    additionalCommentsByPI,
    currentPasswordEntered
  ) => {
    password = currentPasswordEntered;
    selectedReasonToExit = selectedReasonToQuit;
    additionalComments = additionalCommentsByPI;
    if (confirmationType === EXIT_STUDY) {
      validateExitStudyReason();
    } else {
      validateChangedStage();
    }
  };

  const studyInformationHeader = () => {
    return (
      <>
        <div className='card-icon'>
          <ManageSearchOutlinedIcon
            fontSize='small'
            sx={{
              color: colors.colorPrimaryDark,
              height: '24px',
              width: '24px',
            }}
          />
        </div>
        <div className='card-title'>Study Information</div>
      </>
    );
  };

  const studyInformationBody = () => {
    return (
      <div className='study-info-body'>
        <div className='study-column1'>
          <div className='study-column-row'>
            <span className='study-column-labels'>Study</span>
            <span className='info'>{procedure}</span>
          </div>
          <div className='study-column-row'>
            <span className='study-column-labels'>Status</span>
            <div className='info'>
              <CustomizedSelect
                id='select-performer'
                className='select-parameter'
                name='allowedStages'
                disabled={!isStageChangeAllowed}
                placeholder={currentStage}
                value=''
                onChange={onChangePhase}
                options={allowedStages}
              />
            </div>
          </div>
          <div className='study-column-row'>
            <span className='study-column-labels'>Study Exit</span>
            <div className='info'>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: { bgcolor: colors.colorPrimary, fontSize: '12px' },
                  },
                }}
                title={
                  notMovingForward
                    ? `${userProcedure.notMovingForwardReason}`
                    : ''
                }
              >
                <div>
                  <TertiaryButton
                    style={
                      notMovingForward
                        ? {
                            justifyContent: 'flex-start',
                            padding: '0px',
                            pointerEvents: 'none',
                          }
                        : { justifyContent: 'flex-start', padding: '0px' }
                    }
                    disabled={
                      appFeatures[ACCESS_CONTROL_KEYS.EXIT_STUDY].canPerform
                        ? notMovingForward
                        : true
                    }
                    onClick={onStudyExitClicked}
                    className={`${notMovingForward ? 'text-red' : ''}`}
                  >
                    {notMovingForward
                      ? 'Patient has exited the study'
                      : 'Exit Patient from Study'}
                  </TertiaryButton>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className='study-column2'>
          <div className='study-column-row'>
            <span className='study-column-labels'>Site Info</span>
            <span className='info'>{location}</span>
          </div>
          <div className='study-column-row'>
            <span className='study-column-labels'>Cohort</span>
            <span className='info'>{cohort}</span>
          </div>
          {appFeatures[ACCESS_CONTROL_KEYS.DEVICE_INTERROGATION]?.canView && (
            <DeviceInterrogation setShowModal={setShowModal} />
          )}
        </div>
      </div>
    );
  };

  return (
    <PatientInfoCard id='study' className='study'>
      <div className='header'>{studyInformationHeader()}</div>
      <div className='body-content'>{studyInformationBody()}</div>

      <ConfirmationCard
        show={showPhaseModal}
        onHide={onClosePhaseModal}
        confirmationType={confirmationType}
        onConfirmingStageChange={onConfirmingStageChange}
        errors={error}
      />
      <DeviceInterrogationMultiAuth
        showModal={showModal}
        setShowModal={setShowModal}
        userProcedureId={userProcedure?.id}
      />
    </PatientInfoCard>
  );
};

export default StudyInfoCard;
