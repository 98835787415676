export const FETCH_PAYMENT_HISTORIES_REQUEST_SUCCEED =
  'app/payments/FETCH_PAYMENT_HISTORIES_REQUEST_SUCCEED';

export const REFRESH_PAYMENT_HISTORY_REQUEST_SUCCEED =
  'app/payments/REFRESH_PAYMENT_HISTORY_REQUEST_SUCCEED';

export const FETCH_PAYMENT_FILTERS_SUCCEED =
  'app/payments/FETCH_PAYMENT_FILTERS_SUCCEED';

export const SET_APPLIED_FILTERS = 'app/payments/SET_APPLIED_FILTERS';

export const CLEAR_APPLIED_FILTERS = 'app/payments/CLEAR_APPLIED_FILTERS';
