import React from 'react';
import './index.scss';
import Tab from './Tab';

type TabType = {
  id: number;
  text: string;
};

interface TabMenuProps {
  tabs: TabType[];
  activeTab: number;
  onTabChange: (id: number) => void;
}

const TabMenu: React.FC<TabMenuProps> = ({ tabs, activeTab, onTabChange }) => {
  const getEdgeType = (id: number) => {
    if (!tabs || !tabs.length) return 'center';
    if (id === tabs[0].id) return 'left';
    if (id === tabs[tabs.length - 1].id) return 'right';
    return 'center';
  };

  return (
    <div className='tab-menu-wrapper'>
      {tabs.map(tab => {
        return (
          <Tab
            key={tab.id}
            edgeType={getEdgeType(tab.id)}
            isActive={activeTab === tab.id}
            text={tab.text}
            onClick={() => onTabChange(tab.id)}
          />
        );
      })}
    </div>
  );
};

export default TabMenu;
