import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import validate, { clearErrorsForField } from 'common/validator';
import {
  OTHER_REASON_CHARACTERS_LIMIT,
  OTP_CHARACTERS_LIMIT,
  RESEND_OTP_TIME_IN_SECONDS,
} from 'constants';
import './multi_authentication.scss';
import FormGenerator from 'components/FormGenerator';
import {
  getValidationsObject,
  getFormatedPhoneNumber,
} from 'utils/stringUtils';
import { dispatchActionWithCallBack } from 'common/actions';
import {
  fetchDropdownOptionsForMultiAuthentication,
  resetDataForDoubleAuthetication,
  sendOtpForDoubleAuthenticationAction,
  resetOtpSentFlagForDoubleAuthenitcation,
} from './actions';
import {
  PrimaryButton,
  SecondaryButton,
} from '../StyleGuideComponents/common/buttons';
import { PrimaryModal } from '../StyleGuideComponents/common/modals';
import { SecureActionFlowType } from './securedActionCodes';

const MultiAuthenticatedRequest = props => {
  const { show } = props;

  const initialStateData = {
    password: '',
    reason: null,
    otherReasonText: '',
    otp: '',
    helperText: '',
  };

  const [isCommentAllowed, setIsCommentAllowed] = useState(false);
  const [userData, setUserData] = useState(initialStateData);
  const [seconds, setSeconds] = useState(RESEND_OTP_TIME_IN_SECONDS);
  const [intervalId, setIntervalId] = useState(null);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  const mulitAuthenticatedReducerState = useSelector(
    store => store.MultiAuthenticatedRequestReducer
  );

  const { action, data, reasonOptions, mfaFlowId, otpSent } =
    mulitAuthenticatedReducerState;

  const currentUser = useSelector(store => store.currentUser);

  const dispatch = useDispatch();

  const resetDataAfterModalHide = () => {
    setUserData(initialStateData);
    setIsCommentAllowed(false);
    setErrors({});
    dispatch(resetDataForDoubleAuthetication());
    setSeconds(RESEND_OTP_TIME_IN_SECONDS);
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    setShowModal(false);
  };

  const callSendOtp = () => {
    const paramsForSendOtp = {
      mfa_flow_id: mfaFlowId,
      mfa_flow_type: SecureActionFlowType[data.secure_action_code] || '',
    };
    dispatch(
      dispatchActionWithCallBack(
        sendOtpForDoubleAuthenticationAction,
        {
          params: paramsForSendOtp,
        },
        () => {
          setShowModal(true);
        }
      )
    );
  };

  useEffect(() => {
    if (!data.needs_reason)
      console.error(
        'Needs Reason is not provided in Params, please provide the same'
      );
    if (!data.secure_action_code)
      console.error(
        'Secure Action is not provided in Params, please provide the same'
      );
    if (!show) {
      resetDataAfterModalHide();
    } else if (data.needs_reason) {
      dispatch(
        fetchDropdownOptionsForMultiAuthentication(data.secure_action_code)
      );
      if (currentUser.isSsoUser) {
        callSendOtp();
      } else {
        setShowModal(show);
      }
    }
  }, [show]);

  useEffect(() => {
    setUserData({
      ...userData,
      otherReasonText: '',
    });
  }, [userData.reason]);

  useEffect(() => {
    reasonOptions.forEach(reason => {
      if (reason.isDefault) {
        setUserData({
          ...userData,
          reason,
        });
      }
    });
  }, [reasonOptions]);

  useEffect(() => {
    if (otpSent) {
      setUserData({
        ...userData,
        helperText: `OTP sent on ${getFormatedPhoneNumber(
          currentUser.attributes.contactNumber
        )}`,
      });
    }
  }, [otpSent]);

  const countDownTimer = () => {
    clearInterval(intervalId);
    setIntervalId(
      setInterval(() => {
        // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
        setSeconds(seconds => seconds - 1);
      }, 1200)
    );
  };

  useEffect(() => {
    if (seconds === RESEND_OTP_TIME_IN_SECONDS && otpSent) {
      countDownTimer();
    }
    if (seconds === 0) {
      clearInterval(intervalId);
      if (otpSent) {
        setSeconds(RESEND_OTP_TIME_IN_SECONDS);
        dispatch(resetOtpSentFlagForDoubleAuthenitcation());
      }
    }
  }, [seconds, otpSent]);

  const config = [
    {
      id: 'password',
      input_type: 'mui_password',
      name: 'password',
      autoComplete: true,
      label: 'Password',
      placeholder: 'Enter Password',
      validation: [{ rule: 'isRequired', message: 'Password is required' }],
      value: userData.password,
      isInlineLabelNeeded: false,
      required: !currentUser.isSsoUser,
      visible: !currentUser.isSsoUser,
    },
    {
      id: 'otp',
      input_type: 'input_text_field',
      name: 'otp',
      autoComplete: false,
      label: 'Enter otp',
      placeholder: 'Enter OTP',
      validation: [{ rule: 'isRequired', message: 'Otp is required' }],
      value: userData.otp,
      isInlineLabelNeeded: false,
      maxLength: OTP_CHARACTERS_LIMIT,
      required: currentUser.isSsoUser,
      visible: currentUser.isSsoUser,
    },
    {
      id: 'reason',
      input_type: 'mui_single_select',
      name: 'reason',
      label: 'Reason',
      placeholder: 'Select Reson',
      validation: [{ rule: 'isRequired', message: 'Reason is required' }],
      value: userData.reason,
      options: reasonOptions,
      isInlineLabelNeeded: false,
      required: true,
      visible: true,
    },
    {
      id: 'otherReasonText',
      input_type: 'textarea',
      name: 'otherReasonText',
      label: 'Enter Reason',
      type: 'text',
      placeholder: 'Type here...',
      maxLength: OTHER_REASON_CHARACTERS_LIMIT,
      value: userData.otherReasonText,
      validation: [{ rule: 'isRequired', message: 'Reason is required' }],
      visible: isCommentAllowed,
      required: true,
    },
    {
      id: 'helperText',
      input_type: 'helper_text',
      name: 'helperText',
      label: '',
      placeholder: '',
      value: userData.helperText,
      required: false,
      visible: otpSent,
    },
  ];

  const onFormValidationFailure = errorsInForm => {
    setErrors(errorsInForm);
  };

  const onFormValidationSuccess = () => {
    setErrors({});
    const paramsData = { ...data };
    delete paramsData.secure_action_code;
    delete paramsData.needs_reason;
    delete paramsData.re_auth_header;
    delete paramsData.re_auth_information;
    let paramReason = userData.reason?.label;
    if (isCommentAllowed)
      paramReason = `${userData.reason?.label}-${userData.otherReasonText}`;
    paramsData.reason = paramReason;
    if (userData.password.length)
      paramsData.current_password = userData.password;
    if (userData.otp.length) {
      paramsData.otp = userData.otp;
      paramsData.mfa_flow_id = mfaFlowId;
      paramsData.mfa_flow_type =
        SecureActionFlowType[data.secure_action_code] || '';
    }
    dispatch(
      action({
        ...paramsData,
      })
    );
  };

  const submitPasswordForm = e => {
    e.preventDefault();
    validate(
      getValidationsObject(config),
      userData,
      onFormValidationFailure,
      onFormValidationSuccess
    );
  };

  return (
    <PrimaryModal
      show={showModal}
      onHide={() => {
        resetDataAfterModalHide();
      }}
      bodyHeader={{
        text: !currentUser.isSsoUser
          ? data.re_auth_header || 'Please Enter Login Password'
          : 'Please Enter OTP',
      }}
      bodyContent={{
        text: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '12px',
            }}
          >
            {data.re_auth_information ? data.re_auth_information : ''}

            <FormGenerator
              id='re-auth-form'
              className='re-auth-form'
              formConfig={config}
              onChange={(key, value) => {
                clearErrorsForField(errors, key);
                if (key === 'reason') {
                  setIsCommentAllowed(value?.isCommentAllowed);
                  setUserData({ ...userData, [key]: value });
                  clearErrorsForField(errors, 'otherReasonText');
                } else setUserData({ ...userData, [key]: value });
              }}
              label={userData.reason || ''}
              errors={errors}
            />
          </div>
        ),
        align: 'left',
      }}
      buttons={
        currentUser.isSsoUser
          ? [
              <SecondaryButton
                type='submit'
                form='re-auth-form'
                onClick={e => {
                  e.preventDefault();
                  callSendOtp();
                }}
                key='secondaryButton'
                disabled={otpSent}
              >
                {otpSent ? `Resend OTP in ${seconds} sec` : 'Resend'}
              </SecondaryButton>,
              <PrimaryButton
                type='submit'
                form='re-auth-form'
                onClick={submitPasswordForm}
                key='primaryButton'
              >
                Submit OTP
              </PrimaryButton>,
            ]
          : [
              <PrimaryButton
                type='submit'
                form='re-auth-form'
                onClick={submitPasswordForm}
                key='primaryButton'
              >
                Submit Password
              </PrimaryButton>,
            ]
      }
    />
  );
};

MultiAuthenticatedRequest.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default MultiAuthenticatedRequest;
