import {
  fetchLatestReportsData,
  fetchArchivedReportsData,
  fetchReportAuditTrail,
  downloadReport,
  exportReportsAuditTrailsCsv,
} from 'services/reports';
import { AppDispatch } from 'store';
import { PaginationType } from 'types';
import { resetDataForDoubleAuthetication } from 'components/MultiAuthenticatedRequest/actions';
import { ReportDetailType } from './types';
import {
  GET_LATEST_SAFETY_REVIEW_DATA_REPORT_DETAILS_SUCCEEDED,
  GET_ARCHIVED_SAFETY_REVIEW_DATA_REPORT_DETAILS_SUCCEEDED,
  CLEAR_REPORTS_DATA_DETAILS,
  GET_LATEST_FULL_DATA_EXTRACT_REPORT_DETAILS_SUCCEEDED,
  GET_ARCHIVED_FULL_DATA_EXTRACT_REPORT_DETAILS_SUCCEEDED,
  GET_REPORTS_AUDIT_TRAILS_DATA_SUCCEEDED,
} from './constants';

const getLatestSafetyReviewDataReportDetailsSucceeded = (
  data: ReportDetailType
) => ({
  type: GET_LATEST_SAFETY_REVIEW_DATA_REPORT_DETAILS_SUCCEEDED,
  data,
});

const getArchivedSafetyReviewDataReportDetailsSucceeded = (
  data: ReportDetailType[],
  pagination: PaginationType
) => ({
  type: GET_ARCHIVED_SAFETY_REVIEW_DATA_REPORT_DETAILS_SUCCEEDED,
  data: { data, pagination },
});

const getLatestFullDataExtractReportDetailsSucceeded = (
  data: ReportDetailType
) => ({
  type: GET_LATEST_FULL_DATA_EXTRACT_REPORT_DETAILS_SUCCEEDED,
  data,
});

const getArchivedFullDataExtractReportDetailsSucceeded = (
  data: ReportDetailType[],
  pagination: PaginationType
) => ({
  type: GET_ARCHIVED_FULL_DATA_EXTRACT_REPORT_DETAILS_SUCCEEDED,
  data: { data, pagination },
});

export const clearReportDataDetails =
  (tab: string) => (dispatch: AppDispatch) => {
    dispatch({
      type: CLEAR_REPORTS_DATA_DETAILS,
      data: { tab },
    });
  };

export const getReportAuditRailsDataSucceeded = (
  auditTrails,
  auditTrailsHeaders
) => ({
  type: GET_REPORTS_AUDIT_TRAILS_DATA_SUCCEEDED,
  data: { auditTrails, auditTrailsHeaders },
});

export const getLatestReportsData =
  (reportType: string) => (dispatch: AppDispatch) => {
    fetchLatestReportsData(reportType)
      .then(response => {
        if (reportType === 'SafetyDataReport')
          dispatch(
            getLatestSafetyReviewDataReportDetailsSucceeded(response.data)
          );
        if (reportType === 'FullDataReport')
          dispatch(
            getLatestFullDataExtractReportDetailsSucceeded(response.data)
          );
      })
      .catch(() => {});
  };

export const getArchivedReportsData =
  (reportType: string, pageNumber: number) => (dispatch: AppDispatch) => {
    fetchArchivedReportsData(reportType, pageNumber)
      .then(response => {
        if (reportType === 'SafetyDataReport')
          dispatch(
            getArchivedSafetyReviewDataReportDetailsSucceeded(
              response.data.archivedDataReports,
              response.data.pagination
            )
          );
        if (reportType === 'FullDataReport')
          dispatch(
            getArchivedFullDataExtractReportDetailsSucceeded(
              response.data.archivedDataReports,
              response.data.pagination
            )
          );
      })
      .catch(() => {});
  };

export const getReportAuditTrails = () => (dispatch: AppDispatch) => {
  fetchReportAuditTrail()
    .then(response => {
      dispatch(
        getReportAuditRailsDataSucceeded(
          response.data.auditTrails,
          response.data.auditTrailsHeaders
        )
      );
    })
    .catch(() => {});
};

export const downloadReportAction = params => (dispatch: AppDispatch) => {
  downloadReport(params).then(res => {
    window.open(res.data.url);
  });
  dispatch(resetDataForDoubleAuthetication());
};

export const exportReportsAuditTrails = () => (dispatch: AppDispatch) => {
  exportReportsAuditTrailsCsv()
    .then(response => {
      dispatch(resetDataForDoubleAuthetication());
      window.open(response.data.fileUrl);
    })
    .catch(() => {});
};
