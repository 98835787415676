/*
 *
 * PatientDetailsPage reducer
 *
 */
import { isTBD } from 'utils/dateUtils';
import {
  GET_PATIENT_DETAILS_REQUEST_SENT,
  GET_PATIENT_DETAILS_REQUEST_SUCCEED,
  GET_PATIENT_DETAILS_REQUEST_FAILED,
  REMOVE_PATIENT_REQUEST_SENT,
  REMOVE_PATIENT_REQUEST_SUCCEED,
  REMOVE_PATIENT_REQUEST_FAILED,
  UPDATE_NEEDS_ATTENTION_REQUEST_SENT,
  UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED,
  UPDATE_NEEDS_ATTENTION_REQUEST_FAILED,
  RESET_NEEDS_ATTENTION,
  SET_PHASE_ID,
  RESEND_INVITE_REQUEST_SENT,
  RESEND_INVITE_REQUEST_SUCCEED,
  RESEND_INVITE_REQUEST_FAILED,
  RESET_PATIENT_DETAILS,
  UPDATE_PATIENT_INFO_REQUEST_SENT,
  UPDATE_PATIENT_INFO_REQUEST_SUCCEED,
  UPDATE_PATIENT_INFO_REQUEST_FAILED,
  TERMINATE_PROCEDURE_TRIAL_REQUEST_SENT,
  TERMINATE_PROCEDURE_TRIAL_REQUEST_SUCCEED,
  TERMINATE_PROCEDURE_TRIAL_REQUEST_FAILED,
  GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SENT,
  GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SUCCEED,
  GET_NOT_MOVING_FORWARD_REASONS_REQUEST_FAILED,
  UPDATE_NOT_MOVING_FORWARD_REQUEST_SENT,
  UPDATE_NOT_MOVING_FORWARD_REQUEST_SUCCEED,
  UPDATE_NOT_MOVING_FORWARD_REQUEST_FAILED,
  UPDATE_PHASE_REQUEST_SENT,
  UPDATE_PHASE_REQUEST_SUCCEEDED,
  UPDATE_PHASE_REQUEST_FAILED,
  EXIT_PATIENT_FROM_STUDY_REQUEST_SENT,
  EXIT_PATIENT_FROM_STUDY_REQUEST_SUCCEEDED,
  EXIT_PATIENT_FROM_STUDY_REQUEST_FAILED,
  RESET_FLAG_FOR_PATIENT_INFO_UPDATED,
  REVOKE_ACCOUNT_DELETION_REQUEST_SUCCEED,
  RESET_UNREAD_DIARY_COUNT,
  FETCH_DEVICE_INTERROGATION_OPTIONS_SUCCEEDED,
} from './constants';

import { RESET_GLOBAL_REDUCERS } from '../../constants';

const initialState = {
  isLoading: false,
  patient: {
    id: 0,
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipcode: '',
    email: '',
    contactNumber: '',
    dob: '',
    isSubjectIdEditable: false,
    confirmedAt: '',
    cacheKey: '',
    profilePhotoUrl: null,
    userProcedures: [],
    isCurrentProcedureTBD: false,
    relationshipType: '',
    subjectStudyId: '',
    isAccountDeleteRequested: false,
  },
  selectedPhaseId: null,
  phases: [],
  currentPhase: {},
  departmentConfiguration: [],
  hasOutcomeTemplates: false,
  hasSurveys: false,
  userHasOptionalTasks: false,
  isProcTrialReqInProgress: false,
  procedureHasOptionalTasks: false,
  stages: {},
};

export const patientDetailsReducer = (state = initialState, action) => {
  let currentProcedure = null;
  switch (action.type) {
    case GET_PATIENT_DETAILS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isCurrentProcedureTBD: false,
      };
    case GET_PATIENT_DETAILS_REQUEST_SENT:
      return {
        ...state,
        selectedPhaseId: null,
        isLoading: true,
        isCurrentProcedureTBD: false,
      };
    case RESET_UNREAD_DIARY_COUNT:
      return {
        ...state,
        patient: {
          ...state.patient,
          userProcedures: state.patient.userProcedures.map(up => {
            if (up.id === action.procedureId) {
              return { ...up, newDiaryEntry: 0 };
            }
            return up;
          }),
        },
      };
    case GET_PATIENT_DETAILS_REQUEST_SUCCEED:
      currentProcedure = action.payload.patientInfo.patient.userProcedures.find(
        item => item.id === parseInt(action.payload.userProcedureId, 10)
      );
      return {
        ...state,
        isLoading: false,
        ...action.payload.patientInfo,
        patient: {
          ...state.patient,
          ...action.payload.patientInfo.patient,
        },
        isCurrentProcedureTBD:
          currentProcedure !== null
            ? isTBD(currentProcedure.utcDatetime)
            : false,
        phases: [...action.payload.patientInfo.phases],
        currentPhase: { ...action.payload.patientInfo.currentPhase },
        departmentConfiguration:
          action.payload.patientInfo.departmentConfiguration,
        hasOutcomeTemplates: action.payload.patientInfo.hasOutcomeTemplates,
        userHasOptionalTasks: action.payload.patientInfo.userHasOptionalTasks,
        hasSurveys: action.payload.patientInfo.hasSubmittedSurveys,
        procedureHasOptionalTasks:
          action.payload.patientInfo.procedureHasOptionalTasks,
        stages: { ...action.payload.patientInfo.stages },
      };
    case SET_PHASE_ID:
      return {
        ...state,
        selectedPhaseId: action.phaseId,
      };
    case UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED:
      return {
        ...state,
        patient: {
          ...state.patient,
          userProcedures: state.patient.userProcedures.map(userProcedure => {
            if (action.user_procedure_id === userProcedure.id)
              return {
                ...userProcedure,
                needsAttention: action.response.data.needsAttention,
              };
            return userProcedure;
          }),
        },
      };
    case TERMINATE_PROCEDURE_TRIAL_REQUEST_SENT:
      return {
        ...state,
        isProcTrialReqInProgress: true,
      };
    case TERMINATE_PROCEDURE_TRIAL_REQUEST_SUCCEED:
    case TERMINATE_PROCEDURE_TRIAL_REQUEST_FAILED:
      return {
        ...state,
        isProcTrialReqInProgress: false,
      };
    case REVOKE_ACCOUNT_DELETION_REQUEST_SUCCEED:
      return {
        ...state,
        patient: {
          ...state.patient,
          isAccountDeleteRequested: false,
        },
      };
    case RESET_GLOBAL_REDUCERS:
    case RESET_PATIENT_DETAILS:
      return initialState;
    default:
      return state;
  }
};

const removePatientInitState = {
  isDeleting: false,
  hasDeleted: false,
};

export const removePatientReducer = (
  state = removePatientInitState,
  action
) => {
  switch (action.type) {
    case REMOVE_PATIENT_REQUEST_SENT:
      return {
        ...state,
        isDeleting: true,
        hasDeleted: false,
      };
    case REMOVE_PATIENT_REQUEST_SUCCEED:
      return {
        ...state,
        isDeleting: false,
        hasDeleted: true,
      };
    case REMOVE_PATIENT_REQUEST_FAILED:
      return {
        ...state,
        isDeleting: false,
        hasDeleted: false,
      };
    case RESET_PATIENT_DETAILS:
      return removePatientInitState;
    default:
      return state;
  }
};

const needsAttentionInitState = {
  isUpdating: false,
  needsAttention: undefined,
  hasError: false,
};

export const needsAttentionReducer = (
  state = needsAttentionInitState,
  action
) => {
  switch (action.type) {
    case UPDATE_NEEDS_ATTENTION_REQUEST_SENT:
      return {
        ...state,
        isUpdating: true,
        hasError: false,
      };
    case UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED:
      return {
        ...state,
        isUpdating: false,
        needsAttention: action.response.data.needsAttention,
      };
    case UPDATE_NEEDS_ATTENTION_REQUEST_FAILED:
      return {
        ...state,
        isUpdating: false,
        hasError: true,
        needsAttention: action.response.data.needs_attention,
      };
    case RESET_NEEDS_ATTENTION:
      return needsAttentionInitState;
    default:
      return state;
  }
};

const resendInviteInitState = {
  isSending: false,
  hasError: false,
};

export const resendInviteReducer = (state = resendInviteInitState, action) => {
  switch (action.type) {
    case RESEND_INVITE_REQUEST_SENT:
      return {
        ...state,
        isSending: true,
        hasError: false,
      };
    case RESEND_INVITE_REQUEST_SUCCEED:
      return {
        ...state,
        isSending: false,
      };
    case RESEND_INVITE_REQUEST_FAILED:
      return {
        ...state,
        isSending: false,
        hasError: true,
      };
    default:
      return state;
  }
};

const patientInfoInitState = {
  isUpdating: false,
  infoUpdated: false,
};

export const updatePatientInfoReducer = (
  state = patientInfoInitState,
  action
) => {
  switch (action.type) {
    case UPDATE_PATIENT_INFO_REQUEST_SENT:
      return {
        ...state,
        isUpdating: true,
        infoUpdated: false,
      };
    case UPDATE_PATIENT_INFO_REQUEST_SUCCEED:
      return {
        ...state,
        isUpdating: false,
        infoUpdated: true,
      };
    case UPDATE_PATIENT_INFO_REQUEST_FAILED:
      return {
        ...state,
        isUpdating: false,
        infoUpdated: false,
      };
    case RESET_FLAG_FOR_PATIENT_INFO_UPDATED:
      return {
        ...state,
        infoUpdated: false,
      };
    default:
      return state;
  }
};

const notMovingForwardInitState = {
  reasons: {},
  hasError: false,
  isLoading: false,
  notMovingForward: undefined,
  notMovingForwardReason: '',
  notMovingForwardAdditionalComment: '',
};

export const notMovingForwardReducer = (
  state = notMovingForwardInitState,
  action
) => {
  switch (action.type) {
    case GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SENT:
    case UPDATE_NOT_MOVING_FORWARD_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        reasons: action.response.reasons,
      };
    case UPDATE_NOT_MOVING_FORWARD_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        notMovingForward: action.response.data.notMovingForward,
        notMovingForwardReason: action.response.data.notMovingForwardReason,
        notMovingForwardAdditionalComment:
          action.response.data.notMovingForwardAdditionalComment,
      };
    case GET_NOT_MOVING_FORWARD_REASONS_REQUEST_FAILED:
    case UPDATE_NOT_MOVING_FORWARD_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
};

const setPhaseInitialState = {
  hasError: false,
  isLoading: false,
  response: {},
};

export const setPhaseReducer = (state = setPhaseInitialState, action) => {
  switch (action.type) {
    case UPDATE_PHASE_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case UPDATE_PHASE_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        response: action.response,
      };
    case UPDATE_PHASE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
};

const exitPatientFromStudyInitialState = {
  hasError: false,
  isLoading: false,
  response: {},
};

export const exitPatientFromStudyReducer = (
  state = exitPatientFromStudyInitialState,
  action
) => {
  switch (action.type) {
    case EXIT_PATIENT_FROM_STUDY_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case EXIT_PATIENT_FROM_STUDY_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
      };
    case EXIT_PATIENT_FROM_STUDY_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
};

const deviceInterrogationReducerInitialState = {
  deviceInterrogationEvents: [],
  disabled: false,
};

export const deviceInterrogationReducer = (
  state = deviceInterrogationReducerInitialState,
  action
) => {
  switch (action.type) {
    case FETCH_DEVICE_INTERROGATION_OPTIONS_SUCCEEDED:
      return {
        ...state,
        deviceInterrogationEvents: action.data.events,
        disabled: action.data.disabled,
      };
    default:
      return state;
  }
};

export default patientDetailsReducer;
