import { REPORT_GENERATION_FETCHING_SUCCEEDED } from './constants';

const initialData = {
  safetyDataReportGeneratedOn: null,
  fullDataReportGeneratedOn: null,
};

const ReportsAdminReducer = (state = initialData, action) => {
  switch (action.type) {
    case REPORT_GENERATION_FETCHING_SUCCEEDED:
      return {
        ...state,
        safetyDataReportGeneratedOn: action.data.safetyDataReportGeneratedOn,
        fullDataReportGeneratedOn: action.data.fullDataReportGeneratedOn,
      };

    default:
      return state;
  }
};

export default ReportsAdminReducer;
