import EmptyView from 'components/Emptyview';
import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import {
  AuditTrailDataHeaderType,
  AuditTrailDataType,
} from 'containers/AdminPage/AuditTrailAdmin/types';
import AuditTrailTable from 'containers/AuditTrail/AuditTrailTable';
import { useAppDispatch } from 'hooks';
import React from 'react';
import { exportReportsAuditTrails } from '../actions';

interface ReportAuditTrailProps {
  auditTrailsHeaders: AuditTrailDataHeaderType;
  auditTrails: AuditTrailDataType;
}

const ReportAuditTrails: React.FC<ReportAuditTrailProps> = ({
  auditTrails,
  auditTrailsHeaders,
}) => {
  const dispatch = useAppDispatch();
  const exportCSV = () => {
    dispatch(exportReportsAuditTrails());
  };
  return auditTrails.length ? (
    <div className='report-audit-trails-wrapper'>
      <div className='report-audit-trails-buttons'>
        <TertiaryButton style={{ width: 'fit-content' }} onClick={exportCSV}>
          <span className='icon icon-download' />
          &nbsp;
          <span>EXPORT CSV</span>
        </TertiaryButton>
        <TertiaryButton
          style={{ width: 'fit-content' }}
          onClick={() => {
            window.print();
          }}
        >
          <span className='icon icon-font-a-print' />
          &nbsp;
          <span>PRINT</span>
        </TertiaryButton>
      </div>
      <AuditTrailTable data={auditTrails} headers={auditTrailsHeaders} />
    </div>
  ) : (
    <>
      <div />
      <EmptyView
        className='icon icon-featured_play_list'
        message='No Audit Trails'
      />
    </>
  );
};
export default ReportAuditTrails;
