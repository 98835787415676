/*
 *
 * PatientDetailsPage actions
 *
 */
import { showToaster } from 'common/toasterActions';

import {
  setPhaseChangeStarted,
  setExitStudyStarted,
  setEditPatientStarted,
} from 'common/actions';
import { getProcedureManagersAction } from 'components/TeamInformation/actions';

import { resetDataForDoubleAuthetication } from 'components/MultiAuthenticatedRequest/actions';
import {
  getPatientDetails,
  removePatient,
  updateNeedsAttention,
  resendInvite,
  updatePatientInfo,
  updateNotMovingForward,
  getPatientNotMovingForwardReasons,
  trackPatientAsViewed,
  updateSalesforceCheckbox,
  setSelectedPhase,
  exitPatientFromStudy,
  revokeAccountDeletion,
  fetchDeviceInterrogationOptions,
  completeDeviceInterrogation,
} from '../../services/patientDetails';
import {
  GET_PATIENT_DETAILS_REQUEST_SENT,
  GET_PATIENT_DETAILS_REQUEST_SUCCEED,
  GET_PATIENT_DETAILS_REQUEST_FAILED,
  REMOVE_PATIENT_REQUEST_SENT,
  REMOVE_PATIENT_REQUEST_SUCCEED,
  REMOVE_PATIENT_REQUEST_FAILED,
  UPDATE_NEEDS_ATTENTION_REQUEST_SENT,
  UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED,
  UPDATE_NEEDS_ATTENTION_REQUEST_FAILED,
  RESET_NEEDS_ATTENTION,
  SET_PHASE_ID,
  RESEND_INVITE_REQUEST_SENT,
  RESEND_INVITE_REQUEST_SUCCEED,
  RESEND_INVITE_REQUEST_FAILED,
  UPDATE_PATIENT_INFO_REQUEST_SENT,
  UPDATE_PATIENT_INFO_REQUEST_SUCCEED,
  UPDATE_PATIENT_INFO_REQUEST_FAILED,
  RESET_PATIENT_DETAILS,
  GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SENT,
  GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SUCCEED,
  GET_NOT_MOVING_FORWARD_REASONS_REQUEST_FAILED,
  UPDATE_NOT_MOVING_FORWARD_REQUEST_SENT,
  UPDATE_NOT_MOVING_FORWARD_REQUEST_SUCCEED,
  UPDATE_NOT_MOVING_FORWARD_REQUEST_FAILED,
  TRACK_PATIENT_AS_VIEWED_REQUEST_SENT,
  TRACK_PATIENT_AS_VIEWED_REQUEST_SUCCEED,
  TRACK_PATIENT_AS_VIEWED_REQUEST_FAILED,
  UPDATE_SALESFORCE_ACCOUNT_FLAG_REQUEST_SENT,
  UPDATE_SALESFORCE_ACCOUNT_FLAG_REQUEST_SUCCEEDED,
  UPDATE_SALESFORCE_ACCOUNT_FLAG_REQUEST_FAILED,
  UPDATE_PHASE_REQUEST_SENT,
  UPDATE_PHASE_REQUEST_SUCCEEDED,
  UPDATE_PHASE_REQUEST_FAILED,
  EXIT_PATIENT_FROM_STUDY_REQUEST_SENT,
  EXIT_PATIENT_FROM_STUDY_REQUEST_SUCCEEDED,
  EXIT_PATIENT_FROM_STUDY_REQUEST_FAILED,
  RESET_FLAG_FOR_PATIENT_INFO_UPDATED,
  REVOKE_ACCOUNT_DELETION_REQUEST_SUCCEED,
  RESET_UNREAD_DIARY_COUNT,
  FETCH_DEVICE_INTERROGATION_OPTIONS_SUCCEEDED,
} from './constants';

export const getPatientDetailsRequestSent = () => ({
  type: GET_PATIENT_DETAILS_REQUEST_SENT,
});

export const getPatientDetailsRequestSucceeded = (
  patientInfo,
  userProcedureId
) => ({
  type: GET_PATIENT_DETAILS_REQUEST_SUCCEED,
  payload: {
    patientInfo,
    userProcedureId,
  },
});

export const getPatientDetailsRequestFailed = () => ({
  type: GET_PATIENT_DETAILS_REQUEST_FAILED,
});

export const trackPatientAsViewedRequestSent = () => ({
  type: TRACK_PATIENT_AS_VIEWED_REQUEST_SENT,
});

export const trackPatientAsViewedRequestSucceeded = () => ({
  type: TRACK_PATIENT_AS_VIEWED_REQUEST_SUCCEED,
});

export const trackPatientAsViewedRequestFailed = () => ({
  type: TRACK_PATIENT_AS_VIEWED_REQUEST_FAILED,
});

export const revokingPatientAccountDeletionRequestSucceed = () => {
  return {
    type: REVOKE_ACCOUNT_DELETION_REQUEST_SUCCEED,
  };
};

export const trackPatientAsViewedRequest =
  (id, userProcedureId) => dispatch => {
    dispatch(trackPatientAsViewedRequestSent());
    trackPatientAsViewed(id, userProcedureId)
      .then(response => {
        dispatch(
          trackPatientAsViewedRequestSucceeded(response.data, userProcedureId)
        );
      })
      .catch(() => {
        dispatch(trackPatientAsViewedRequestFailed());
      });
  };

export const setPhaseId = phaseId => ({
  type: SET_PHASE_ID,
  phaseId,
});

export const resetNeedsAttention = () => ({
  type: RESET_NEEDS_ATTENTION,
});

export const resetUnreadDiaryCount = procedureId => ({
  type: RESET_UNREAD_DIARY_COUNT,
  procedureId,
});

export const getPatientInfo = (id, userProcedureId) => dispatch => {
  dispatch(getPatientDetailsRequestSent());
  getPatientDetails(id, userProcedureId)
    .then(response => {
      dispatch(
        getPatientDetailsRequestSucceeded(response.data, userProcedureId)
      );
      dispatch(resetNeedsAttention());
    })
    .catch(() => {
      dispatch(getPatientDetailsRequestFailed());
    });
};

export const removePatientRequestSent = () => ({
  type: REMOVE_PATIENT_REQUEST_SENT,
});

export const removePatientRequestSucceeded = () => ({
  type: REMOVE_PATIENT_REQUEST_SUCCEED,
});

export const removePatientRequestFailed = () => ({
  type: REMOVE_PATIENT_REQUEST_FAILED,
});

export const removePatientAction = (params, password) => dispatch => {
  dispatch(removePatientRequestSent());
  removePatient({ ...params, current_password: password })
    .then(() => {
      dispatch(removePatientRequestSucceeded());
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: 'Procedure removed successfully.' }));
    })
    .catch(() => {
      dispatch(removePatientRequestFailed());
    });
};

export const updateNeedsAttentionRequestSent = () => ({
  type: UPDATE_NEEDS_ATTENTION_REQUEST_SENT,
});

export const updateNeedsAttentionRequestSucceeded = (
  response,
  user_procedure_id
) => ({
  type: UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED,
  response,
  user_procedure_id,
});

export const updateNeedsAttentionRequestFailed = response => ({
  type: UPDATE_NEEDS_ATTENTION_REQUEST_FAILED,
  response,
});

export const updateNeedsAttentionAction = params => dispatch => {
  dispatch(updateNeedsAttentionRequestSent());
  updateNeedsAttention(params)
    .then(response => {
      dispatch(
        updateNeedsAttentionRequestSucceeded(response, params.user_procedure_id)
      );
      dispatch(resetDataForDoubleAuthetication());
    })
    .catch(error => {
      dispatch(updateNeedsAttentionRequestFailed(error.response));
      if (error.response.data.message)
        dispatch(
          showToaster({ message: error.response.data.message, type: 'error' })
        );
    });
};

export const getNotMovingForwardReasonsRequestSent = () => ({
  type: GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SENT,
});

export const getNotMovingForwardReasonsRequestSucceeded = response => ({
  type: GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SUCCEED,
  response,
});

export const getNotMovingForwardReasonsRequestFailed = () => ({
  type: GET_NOT_MOVING_FORWARD_REASONS_REQUEST_FAILED,
});

export const getNotMovingForwardReasons = params => dispatch => {
  dispatch(getNotMovingForwardReasonsRequestSent());
  getPatientNotMovingForwardReasons(params)
    .then(response => {
      dispatch(getNotMovingForwardReasonsRequestSucceeded(response.data));
    })
    .catch(() => {
      dispatch(getNotMovingForwardReasonsRequestFailed());
    });
};

export const updateNotMovingForwardRequestSent = () => ({
  type: UPDATE_NOT_MOVING_FORWARD_REQUEST_SENT,
});

export const updateNotMovingForwardRequestSucceeded = response => ({
  type: UPDATE_NOT_MOVING_FORWARD_REQUEST_SUCCEED,
  response,
});

export const updateNotMovingForwardRequestFailed = response => ({
  type: UPDATE_NOT_MOVING_FORWARD_REQUEST_FAILED,
  response,
});

export const updateNotMovingForwardAction = params => dispatch => {
  dispatch(updateNotMovingForwardRequestSent());
  updateNotMovingForward(params)
    .then(response => {
      dispatch(updateNotMovingForwardRequestSucceeded(response));
    })
    .catch(error => {
      dispatch(updateNotMovingForwardRequestFailed(error.response));
      dispatch(
        showToaster({ message: error.response.data.message, type: 'error' })
      );
    });
};

export const resetPatientDetails = () => ({
  type: RESET_PATIENT_DETAILS,
});

export const resendInviteRequestSent = () => ({
  type: RESEND_INVITE_REQUEST_SENT,
});

export const resendInviteRequestSucceeded = response => ({
  type: RESEND_INVITE_REQUEST_SUCCEED,
  response,
});

export const resendInviteRequestFailed = response => ({
  type: RESEND_INVITE_REQUEST_FAILED,
  response,
});

export const resendInviteAction = params => dispatch => {
  dispatch(resendInviteRequestSent());
  resendInvite(params)
    .then(response => {
      dispatch(resendInviteRequestSucceeded(response));
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: response.data.message }));
      dispatch(getPatientInfo(params.id, params.user_procedure_id));
    })
    .catch(error => {
      dispatch(resendInviteRequestFailed(error.response));
    });
};

export const updatePatientInfoRequestSent = () => ({
  type: UPDATE_PATIENT_INFO_REQUEST_SENT,
});

export const updatePatientInfoRequestSucceeded = () => ({
  type: UPDATE_PATIENT_INFO_REQUEST_SUCCEED,
});

export const updatePatientInfoRequestFailed = () => ({
  type: UPDATE_PATIENT_INFO_REQUEST_FAILED,
});

export const resetFlagForPatientInfoUpdated = () => ({
  type: RESET_FLAG_FOR_PATIENT_INFO_UPDATED,
});

export const updatePatientInfoAction = params => dispatch => {
  dispatch(updatePatientInfoRequestSent());
  updatePatientInfo(params)
    .then(() => {
      dispatch(updatePatientInfoRequestSucceeded());
      dispatch(
        showToaster({ message: 'Patient details updated successfully.' })
      );
      dispatch(setEditPatientStarted());
      dispatch(resetDataForDoubleAuthetication());
      dispatch(getPatientInfo(params.id, params.userProcedureId));
      dispatch(
        getProcedureManagersAction({
          user_procedure_id: params.userProcedureId,
        })
      );
    })
    .catch(() => {
      dispatch(updatePatientInfoRequestFailed());
    });
};

export const updateSalesforceAccountFlagRequestSent = () => ({
  type: UPDATE_SALESFORCE_ACCOUNT_FLAG_REQUEST_SENT,
});

export const updateSalesforceAccountFlagRequestSucceeded = () => ({
  type: UPDATE_SALESFORCE_ACCOUNT_FLAG_REQUEST_SUCCEEDED,
});

export const updateSalesforceAccountFlagRequestSentRequestFailed = () => ({
  type: UPDATE_SALESFORCE_ACCOUNT_FLAG_REQUEST_FAILED,
});

export const updateSalesforceAccountFlagAction = procedureId => dispatch => {
  dispatch(updateSalesforceAccountFlagRequestSent());
  updateSalesforceCheckbox(procedureId)
    .then(() => {
      dispatch(updateSalesforceAccountFlagRequestSucceeded());
    })
    .catch(() => {
      dispatch(updateSalesforceAccountFlagRequestSentRequestFailed());
    });
};

export const updatePhaseRequestSent = () => ({
  type: UPDATE_PHASE_REQUEST_SENT,
});

export const updatePhaseRequestSucceeded = () => ({
  type: UPDATE_PHASE_REQUEST_SUCCEEDED,
});

export const updatePhaseRequestFailed = () => ({
  type: UPDATE_PHASE_REQUEST_FAILED,
});

export const updatePhaseAction = params => dispatch => {
  dispatch(updatePhaseRequestSent());
  setSelectedPhase(params)
    .then(() => {
      dispatch(setPhaseChangeStarted());
      dispatch(updatePhaseRequestSucceeded());
      dispatch(getPatientInfo(params.id, params.user_procedure_id));
    })
    .catch(() => {
      dispatch(updatePhaseRequestFailed());
    });
};

export const exitPatientFromStudyRequestSent = () => ({
  type: EXIT_PATIENT_FROM_STUDY_REQUEST_SENT,
});

export const exitPatientFromStudyRequestSucceeded = () => ({
  type: EXIT_PATIENT_FROM_STUDY_REQUEST_SUCCEEDED,
});

export const exitPatientFromStudyRequestFailed = () => ({
  type: EXIT_PATIENT_FROM_STUDY_REQUEST_FAILED,
});

export const exitPatientFromStudyAction = params => dispatch => {
  dispatch(exitPatientFromStudyRequestSent());
  exitPatientFromStudy(params)
    .then(response => {
      dispatch(setExitStudyStarted());
      dispatch(exitPatientFromStudyRequestSucceeded());
      dispatch(getPatientInfo(params.userId, params.userProcedureId));
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(error => {
      dispatch(exitPatientFromStudyRequestFailed());
      dispatch(
        showToaster({ message: error.response.data.error, type: 'error' })
      );
    });
};

export const revokeAccountDeletionRequest = params => dispatch => {
  revokeAccountDeletion(params)
    .then(response => {
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: response.data.message }));
      dispatch(revokingPatientAccountDeletionRequestSucceed());
    })
    .catch(() => {});
};

export const fetchDeviceInterrogationOptionsSucceeded = data => ({
  type: FETCH_DEVICE_INTERROGATION_OPTIONS_SUCCEEDED,
  data,
});

export const fetchDeviceInterrogationOptionsRequest = params => dispatch => {
  fetchDeviceInterrogationOptions(params)
    .then(response => {
      dispatch(fetchDeviceInterrogationOptionsSucceeded(response.data));
    })
    .catch(() => {});
};

export const completeDeviceInterrogationRequest = params => dispatch => {
  completeDeviceInterrogation(params)
    .then(response => {
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {});
};
