import { ACCESS_CONTROL_KEYS } from 'constants';

const defaultAppFeatures = {
  [ACCESS_CONTROL_KEYS.PATIENT_DEMOGRAPHICS]: {
    canView: false,
    canCreate: false,
    canEdit: false,
  },
  [ACCESS_CONTROL_KEYS.VISITS]: {
    canView: false,
    canCreate: false,
    canEdit: false,
    canDelete: false,
  },
  [ACCESS_CONTROL_KEYS.TASKS]: {
    canView: false,
    canRemind: false,
    canMarkComplete: false,
    canDelete: false,
  },
  [ACCESS_CONTROL_KEYS.SURVEYS]: {
    canView: false,
    canClear: false,
    canSubmit: false,
  },
  [ACCESS_CONTROL_KEYS.MESSAGES]: {
    canView: false,
    canCreate: false,
  },
  [ACCESS_CONTROL_KEYS.DIARY]: {
    canView: false,
    canCreate: false,
    canEdit: false,
  },
  [ACCESS_CONTROL_KEYS.PAPER_CONSENT]: {
    canUpload: false,
  },
  [ACCESS_CONTROL_KEYS.NOTES]: {
    canView: false,
    canCreate: false,
  },
  [ACCESS_CONTROL_KEYS.CONSENT]: {
    canView: false,
    canDownload: false,
  },
  [ACCESS_CONTROL_KEYS.AUDIT_TRAIL]: {
    canView: false,
  },
  [ACCESS_CONTROL_KEYS.OUTCOMES_REPORTS]: {
    canView: false,
  },
  [ACCESS_CONTROL_KEYS.REVOKE_ACCOUNT_DELETION]: {
    canClear: false,
  },
  [ACCESS_CONTROL_KEYS.EXIT_STUDY]: {
    canPerform: false,
  },
  [ACCESS_CONTROL_KEYS.PHASE_CHANGE]: {
    canPerform: false,
  },
  [ACCESS_CONTROL_KEYS.TAG_FOR_FOLLOW_UP]: {
    canEdit: false,
  },
  [ACCESS_CONTROL_KEYS.PAYMENT_HISTORY]: {
    canView: false,
  },
  [ACCESS_CONTROL_KEYS.DATA_REPORTS]: {
    canView: false,
  },
};

export default defaultAppFeatures;
