import {
  fetchReportGenerationInfo,
  generateDataReport,
} from 'services/reports';
import { AppDispatch } from 'store';
import { resetDataForDoubleAuthetication } from 'components/MultiAuthenticatedRequest/actions';
import { showToaster } from 'common/toasterActions';
import { REPORT_GENERATION_FETCHING_SUCCEEDED } from './constants';

export const reportGerenationInfoFetchSucceeded = data => ({
  type: REPORT_GENERATION_FETCHING_SUCCEEDED,
  data,
});

export const getReportGenerationInfo = () => (dispatch: AppDispatch) => {
  fetchReportGenerationInfo()
    .then(res => {
      dispatch(reportGerenationInfoFetchSucceeded(res.data));
    })
    .catch(error => {
      console.log(error);
    });
};

export const triggerReportGenereation = params => (dispatch: AppDispatch) => {
  generateDataReport(params).then(res => {
    dispatch(resetDataForDoubleAuthetication());
    dispatch(showToaster({ message: res.data.message }));
  });
};
