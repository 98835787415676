import React from 'react';
import './styleGuideComponents.scss';

type CheckBoxProps = {
  disabled: boolean;
  name: string;
  checked: boolean;
  onChange: () => void;
  label: string;
};

const CheckBox: React.FC<CheckBoxProps> = props => {
  const { disabled, name, checked, onChange, label } = props;

  return (
    <div className='checkbox-input'>
      <input
        type='checkbox'
        disabled={disabled}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      {label ? (
        <span className={`checkbox-label ${disabled ? 'disabled' : ''}`}>
          {label}
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

export default CheckBox;
