import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { SecondaryButton } from 'components/StyleGuideComponents/common/buttons';
import { useParams } from 'react-router-dom';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import PropTypes from 'prop-types';
import { ACCESS_CONTROL_KEYS } from 'constants';
import {
  downloadSignedConsents,
  downloadUninitiatedConsents,
} from '../../services/patientDetails';
import { consentResendInviteRequest } from './action';
import DownloadFile from '../../components/DownloadFile';
import UploadConsentForm from './UploadConsentForm';

const ConsentCard = props => {
  const dispatch = useDispatch();
  const params = useParams();
  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  const [showModal, setShowModal] = useState(false);
  const appFeatures = useSelector(store => store.currentUser.appFeatures);
  const canUploadPaperConsent =
    appFeatures[ACCESS_CONTROL_KEYS.PAPER_CONSENT].canUpload;
  const { consent } = props;

  const resendConsent = e => {
    e.preventDefault();
    const data = {
      patient_consent_id: consent.patientConsentId,
      user_procedure_id: parseInt(params.procedureId, 10) || -1,
      secure_action_code: SecureCodes.RESEND_CONSENT,
      needs_reason: true,
    };
    dispatch(
      secureActionWithDoubleAuthentication(consentResendInviteRequest, data)
    );
  };

  const getConsentCardUploadedByText = () => {
    if (consent.status === 'completed') {
      if (consent.uploadedByText) {
        return (
          <div className='completed'>
            <CheckIcon />
            <span>{consent.uploadedByText}</span>
          </div>
        );
      }
      return '';
    }
    return '';
  };

  const getConsentCardCounterSineeStatus = () => {
    if (consent.status === 'signed_by_patient') {
      if (consent.counterSigne.isCurrentUserCounterSigne) {
        return (
          <div className='pending-on-me-subject-complete'>
            <AccessTimeIcon />
            <span>{consent.counterSigne.statusString}</span>
          </div>
        );
      }
      return (
        <div className='pending-on-other'>
          <AccessTimeIcon />
          <span>{consent.counterSigne.statusString}</span>
        </div>
      );
    }

    if (consent.status === 'completed') {
      return (
        <div className='completed'>
          <CheckIcon />
          <span>{consent.counterSigne.statusString}</span>
        </div>
      );
    }

    if (consent.status === 'declined_by_counter_signee') {
      return (
        <div className='declined'>
          <ClearIcon />
          <span>{consent.counterSigne.statusString}</span>
        </div>
      );
    }

    return '';
  };

  const getConsentCardSubjectStatus = () => {
    if (
      consent.status === 'signed_by_patient' ||
      consent.status === 'completed' ||
      consent.status === 'declined_by_counter_signee'
    ) {
      return (
        <div className='subject-consent-completed'>
          <CheckIcon />
          <span>{consent.subject.statusString}</span>
        </div>
      );
    }

    if (consent.status === 'declined_by_patient') {
      return (
        <div className='declined'>
          <ClearIcon />
          <span>{consent.subject.statusString}</span>
        </div>
      );
    }

    return (
      <div className='subject-consent-pending'>
        <AccessTimeIcon />
        <span>{consent.subject.statusString}</span>
      </div>
    );
  };

  return (
    <>
      <UploadConsentForm
        showModal={showModal}
        onModalClose={() => setShowModal(false)}
        consentFormId={consent.consentFormId}
      />
      <div className='consent-card-wrapper border-line'>
        <div className='consent-card-body'>
          <div className='consent-card-title'>
            <span>{consent.title}</span>
            {appFeatures[ACCESS_CONTROL_KEYS.CONSENT].canDownload &&
            consent.isDownloadable ? (
              <DownloadFile
                args={[
                  consent.status !== 'uninitiated'
                    ? {
                        patient_consent_id: consent.patientConsentId,
                        user_procedure_id: procedureId,
                      }
                    : {
                        consent_form_id: consent.consentFormId,
                        patient_id: patientId,
                      },
                ]}
                serviceCall={
                  consent.status !== 'uninitiated'
                    ? downloadSignedConsents
                    : downloadUninitiatedConsents
                }
              >
                <span className='icon icon-download' />
              </DownloadFile>
            ) : (
              ''
            )}
          </div>
          <div className='consent-card-status'>
            {getConsentCardUploadedByText()}
            {consent.counterSigne.statusString && (
              <div className='consent-card-cs-status'>
                {getConsentCardCounterSineeStatus()}
              </div>
            )}
            {consent.subject.statusString && (
              <div className='consent-card-subject-status'>
                {getConsentCardSubjectStatus()}
              </div>
            )}
          </div>
        </div>
        {canUploadPaperConsent && !consent.isPaperConsentDisabled ? (
          <div className='upload-consent-button-wrapper'>
            <span className='upload-consent-button-wrapper-text'>
              Did the subject sign a paper copy of the form?
            </span>
            <SecondaryButton
              className='upload-consent-button'
              onClick={() => setShowModal(true)}
            >
              Upload Executed Form
            </SecondaryButton>
          </div>
        ) : (
          ''
        )}
        {consent.status === 'signed_by_patient' &&
        consent.counterSigne.isCurrentUserCounterSigne ? (
          <SecondaryButton
            style={{ width: '116px', alignSelf: 'center' }}
            onClick={e => resendConsent(e)}
          >
            Resend Email
          </SecondaryButton>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

ConsentCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  consent: PropTypes.object.isRequired,
};
export default ConsentCard;
