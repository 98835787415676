import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Pagination from 'components/Pagination';
import {
  fetchCreateUserDropdownOptionsRequest,
  fetchAllLocationsRequest,
} from 'containers/AdminPage/action';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import AddEditUserModal from './Components/AddEditUserModal';
import AdminTabWrapper from '../AdminTabWrapper';
import UserFilter from './Components/UserFilter';
import UserData from './Components/UserData';
import {
  exportUsersCsvRequest,
  fetchUsersListOnAdminPageRequest,
  setSelectedUserIdRequest,
  updateUserAdminValue,
} from './action';

const UserAdmin = props => {
  const dispatch = useDispatch();

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const userAdminData = useSelector(store => store.userAdminReducer);
  // eslint-disable-next-line react/prop-types
  const { isActive } = props;

  useEffect(() => {
    if (isActive) {
      dispatch(
        fetchUsersListOnAdminPageRequest({
          page: userAdminData.page,
          name: userAdminData.filterNameOrEmail,
        })
      );
      dispatch(fetchCreateUserDropdownOptionsRequest());
      dispatch(fetchAllLocationsRequest());
    } else {
      dispatch(updateUserAdminValue('filterNameOrEmail', ''));
    }
  }, [isActive, userAdminData.page, userAdminData.filterNameOrEmail]);

  const exportCSV = () => {
    dispatch(
      secureActionWithDoubleAuthentication(exportUsersCsvRequest, {
        secure_action_code: SecureCodes.USER_ADMIN_USERS_DATA_EXPORT,
        needs_reason: true,
      })
    );
  };

  return (
    <AdminTabWrapper
      filterSection={<UserFilter />}
      dataSection={{
        header: (
          <div className='export-csv-wrapper'>
            <TertiaryButton
              style={{ width: 'fit-content' }}
              onClick={() => setShowAddEditModal(true)}
            >
              <AddCircleOutlineIcon sx={{ fontSize: '32px' }} />
              <span style={{ fontSize: '16px' }}> Add New User </span>
            </TertiaryButton>
            <TertiaryButton
              style={{ width: 'fit-content' }}
              onClick={() => exportCSV()}
            >
              <span className='icon icon-download download-icon-style' />
              <span className='export-csv-btn'> &nbsp; EXPORT CSV </span>
            </TertiaryButton>
          </div>
        ),
        content: (
          <>
            <UserData
              selectedUserId={selectedUserId}
              data={{
                usersHeaders: userAdminData.usersHeaders,
                usersData: userAdminData.usersData,
              }}
              updateSelectedUserId={data => {
                setSelectedUserId(data);
                dispatch(
                  setSelectedUserIdRequest(
                    data,
                    userAdminData.usersData.find(user => user.id === data)
                  )
                );
                setShowAddEditModal(true);
              }}
            />
            <AddEditUserModal
              show={showAddEditModal}
              onHide={() => setShowAddEditModal(false)}
              updateSelectedUserId={id => setSelectedUserId(id)}
            />
          </>
        ),
        footer: (
          <Pagination
            pagination={userAdminData.pagination}
            title='Users'
            handlePagination={page => {
              dispatch(updateUserAdminValue('page', page));
            }}
          />
        ),
      }}
    />
  );
};

export default UserAdmin;
