import {
  FetchPaymentHistoriesParamsType,
  fetchPaymentHistories,
  RefreshPaymentHistoryParamsType,
  refreshPaymentHistory,
  exportCsv,
  fetchPaymentFilters,
} from 'services/paymentHistory';
import { APIResponse } from 'types';
import { AppDispatch } from 'store';
import * as Constants from './constants';
import {
  ResponseDataType,
  PaymentsDataObjectType,
  FiltersResponseType,
  AppliedFilter,
} from './types';

type FetchPaymentHistoriesAPIResponse = APIResponse & {
  data: ResponseDataType;
};

type RefreshPaymentHistoryAPIResponse = APIResponse & {
  data: PaymentsDataObjectType;
};

type ExportCsvAPIResponse = APIResponse & {
  data: {
    fileUrl: string;
    message: string;
  };
};

type FetchPaymentFiltersAPIResponse = APIResponse & {
  data: FiltersResponseType;
};

export const fetchPaymentHistoriesRequestSucceeded = (
  data: ResponseDataType
) => ({ type: Constants.FETCH_PAYMENT_HISTORIES_REQUEST_SUCCEED, data });

export const refreshPaymentHistoryRequestSucceeded = (
  data: PaymentsDataObjectType
) => ({ type: Constants.REFRESH_PAYMENT_HISTORY_REQUEST_SUCCEED, data });

export const fetchPaymentFiltersRequestSucceeded = (
  data: FiltersResponseType
) => ({ type: Constants.FETCH_PAYMENT_FILTERS_SUCCEED, data });

export const setAppliedFilters = (data: AppliedFilter) => ({
  type: Constants.SET_APPLIED_FILTERS,
  data,
});

export const clearAppliedFilters = () => ({
  type: Constants.CLEAR_APPLIED_FILTERS,
});

// redux thunk actions
export const fetchPaymentHistoriesRequest =
  (params: FetchPaymentHistoriesParamsType) => (dispatch: AppDispatch) => {
    fetchPaymentHistories(params)
      .then((response: FetchPaymentHistoriesAPIResponse) => {
        dispatch(fetchPaymentHistoriesRequestSucceeded(response.data));
      })
      .catch(() => {});
  };

export const refreshPaymentHistoryRequest =
  (params: RefreshPaymentHistoryParamsType) => (dispatch: AppDispatch) => {
    refreshPaymentHistory(params)
      .then((response: RefreshPaymentHistoryAPIResponse) => {
        dispatch(refreshPaymentHistoryRequestSucceeded(response.data));
      })
      .catch(() => {});
  };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const exportCsvRequest = () => (dispatch: AppDispatch) => {
  exportCsv()
    .then((response: ExportCsvAPIResponse) => {
      window.open(response.data.fileUrl);
    })
    .catch(() => {});
};

export const fetchPaymentFiltersRequest = () => (dispatch: AppDispatch) => {
  fetchPaymentFilters()
    .then((response: FetchPaymentFiltersAPIResponse) => {
      dispatch(fetchPaymentFiltersRequestSucceeded(response.data));
    })
    .catch(() => {});
};
