import { useAppDispatch, useAppSelector } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { ACCESS_CONTROL_KEYS } from 'constants.js';
import Tooltip from '@mui/material/Tooltip';
import { TertiaryButton } from 'components/StyleGuideComponents/common/buttons';
import { colors } from 'components/StyleGuideComponents/StyleGuideCSS';
import { fetchDeviceInterrogationOptionsRequest } from 'containers/PatientDetails/actions';
import { useParams } from 'react-router';

type Props = {
  setShowModal: (arg0: boolean) => void;
};

const DeviceInterrogation: FC<Props> = ({ setShowModal }) => {
  const dispatch = useAppDispatch();
  const params = useParams<{ procedureId: string }>();
  const [buttonHoverText, setButtonHoverText] = useState<string>('');

  const appFeatures = useAppSelector(store => store.currentUser.appFeatures);
  const { deviceInterrogationEvents, disabled, hoverText } = useAppSelector(
    store => store.deviceInterrogationReducer
  );

  const canEdit =
    appFeatures[ACCESS_CONTROL_KEYS.DEVICE_INTERROGATION]?.canEdit;

  useEffect(() => {
    dispatch(
      fetchDeviceInterrogationOptionsRequest({
        user_procedure_id: parseInt(params.procedureId, 10) || -1,
      })
    );
  }, []);

  useEffect(() => {
    let text = '';
    let disabled = true;
    deviceInterrogationEvents.forEach(event => {
      if (event.submittedAt !== null) {
        text = text.concat(`${event.label} completed\n`);
      } else if (!event.disabled) {
        text = text.concat(`${event.label} is due\n`);
      } else {
        text = text.concat(`${event.label} is not yet due\n`);
      }
    });
    setButtonHoverText(text);
  }, [deviceInterrogationEvents]);

  return (
    <div className='study-column-row'>
      <span className='study-column-labels'>Device Interrogation</span>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: { bgcolor: colors.colorPrimary, fontSize: '12px' },
          },
        }}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -8],
                },
              },
            ],
          },
        }}
        title={
          <span style={{ whiteSpace: 'pre-line' }}>{buttonHoverText}</span>
        }
      >
        <div className='info'>
          <TertiaryButton
            style={{ padding: '0' }}
            disabled={!canEdit || (canEdit && disabled)}
            onClick={() => {
              setShowModal(true);
            }}
          >
            Mark as Completed
          </TertiaryButton>
        </div>
      </Tooltip>
    </div>
  );
};

export default DeviceInterrogation;
