export const GET_LATEST_SAFETY_REVIEW_DATA_REPORT_DETAILS_SUCCEEDED =
  'app/reports/GET_LATEST_SAFETY_REVIEW_DATA_REPORT_DETAILS_SUCCEEDED';
export const GET_ARCHIVED_SAFETY_REVIEW_DATA_REPORT_DETAILS_SUCCEEDED =
  'app/reports/GET_ARCHIVED_SAFETY_REVIEW_DATA_REPORT_DETAILS_SUCCEEDED';
export const GET_LATEST_FULL_DATA_EXTRACT_REPORT_DETAILS_SUCCEEDED =
  'app/reports/GET_LATEST_FULL_DATA_EXTRACT_REPORT_DETAILS_SUCCEEDED';
export const GET_ARCHIVED_FULL_DATA_EXTRACT_REPORT_DETAILS_SUCCEEDED =
  'app/reports/GET_ARCHIVED_FULL_DATA_EXTRACT_REPORT_DETAILS_SUCCEEDED';
export const CLEAR_SAFETY_REVIEW_DATA_REPORT_DETAILS =
  'app/reports/CLEAR_SAFETY_REVIEW_DATA_REPORT_DETAILS';
export const CLEAR_REPORTS_DATA_DETAILS =
  'app/reports/CLEAR_REPORTS_DATA_DETAILS';
export const GET_REPORTS_AUDIT_TRAILS_DATA_SUCCEEDED =
  'app/reports/GET_REPORTS_AUDIT_TRAILS_DATA_SUCCEEDED';

export const GET_REPORTS_DATA = 'app/reports/GET_REPORTS_DATA';
export const DOWNLOAD_REPORT_DATA = 'app/reports/DOWNLOAD_REPORT_DATA';
