import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AuthenticatedRoute, {
  MedStaffAuthorizedRoute,
  SuperUserAuthorizedRoute,
} from 'containers/AuthRoute';

import Header from 'components/Header';
import * as Sentry from '@sentry/react';
import Toasters from 'components/Toasters';
import Media from 'react-media';

import Dashboard from 'containers/Dashboard/Loadable';
import Login from 'containers/Login/Loadable';
import ForgotPassword from 'containers/ForgotPassword/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import PatientDetails from 'containers/PatientDetails';
import Profile from 'containers/Profile/Loadable';
import PatientSearch from 'containers/PatientSearch/Loadable';
import LookupSearch from 'containers/LookupSearch/loadable';
import ChangePassword from 'containers/ChangePassword/loadable';
import Notifications from 'containers/Notifications/loadable';
import ManagePatientAdvocate from 'containers/ManagePatientAdvocate/loadable';
import ManagePatients from 'containers/ManagePatients/loadable';
import SurveyFeedback from 'containers/SurveyFeedback/Loadable';
import NoteDetails from 'containers/Notes/Components/NoteDetails';
import UnauthorizedUser from 'containers/UnauthorizedUser/loadable';
import ViewDataDownloadRequest from 'containers/ViewDataDownloadRequest/index';
import ViewSiteClosureRequest from 'containers/ViewSiteClosureRequest/index';

import { getItemFromStorage, isWebContainerRequest } from 'services/storage';
import ForceResetPassword from 'containers/ForceResetPassword/loadable';
import DepartmentSurveys from 'containers/DepartmentSurveys/loadable';
import Logout from 'containers/Logout';
import UnSupportedPageView from 'containers/UnSupportedPageView';
import Conversations from 'containers/Conversations';
import SamlCallback from 'containers/Auth/SamlCallback';
import LandingPage from 'containers/LandingPage';
import LoaderDisplay from 'components/LoaderDisplay';
import AdminPage from 'containers/AdminPage';
import PaymentsPage from 'containers/PaymentsPage';
import OutcomesCardSummary from 'containers/QuickViewCharts/Components/OutcomesCardSummary';
import Reports from 'containers/Reports';
import { USER_TYPE_PATIENT } from '../../constants';
import MultiAuthenticatedRequest from '../../components/MultiAuthenticatedRequest';
import ProcedureSummaryQuickView from '../QuickViewCharts/Components/ProcedureSummaryQuickView';

class App extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  // eslint-disable-next-line class-methods-use-this
  showHeader = () => {
    let currentUser = null;

    if (getItemFromStorage('currentUser')) {
      currentUser = JSON.parse(getItemFromStorage('currentUser'));
      return currentUser && currentUser.type === USER_TYPE_PATIENT ? (
        <Header />
      ) : null;
    }
    return <Header />;
  };

  render() {
    const { isRequestMultiAuthenticated, location } = this.props;
    return (
      <div>
        <Sentry.ErrorBoundary
          /* eslint-disable no-unused-vars */
          // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-unused-vars
          fallback={({ error, componentStack, resetError }) => (
            <div className='error-container' />
          )}
          /* eslint-enable no-unused-vars */
        >
          <MultiAuthenticatedRequest show={isRequestMultiAuthenticated} />
          <LoaderDisplay />

          <div
            className={
              location.pathname.indexOf('patient-signup') === -1
                ? 'app-container'
                : ''
            }
          >
            {/* !getItemFromStorage('xealth') && this.props.location.pathname.indexOf('telemedicine-appointment') === -1 */}
            {!getItemFromStorage('xealth') &&
            location.pathname.indexOf('logout') === -1 ? (
              <Media
                queries={{
                  screenIsSmall: '(max-width: 767px)',
                  deviceOrientation: '(orientation: landscape)',
                  screenIsMedium: '(max-width: 1023px)',
                }}
              >
                {matches =>
                  (matches.deviceOrientation && matches.screenIsMedium) ||
                  matches.screenIsSmall ? (
                    this.showHeader()
                  ) : (
                    <Header />
                  )
                }
              </Media>
            ) : null}
            <Toasters />
            <Switch>
              <Route exact path='/signin' component={LandingPage} />
              <Route exact path='/sign-in-with-password' component={Login} />
              <Route
                exact
                path='/view-data-download-request/:key'
                component={ViewDataDownloadRequest}
              />
              <Route
                exact
                path='/view-site-closure-request/:key'
                component={ViewSiteClosureRequest}
              />

              <Route exact path='/forgot-password' component={ForgotPassword} />
              <MedStaffAuthorizedRoute exact path='/' component={Dashboard} />
              <MedStaffAuthorizedRoute
                exact
                path='/patients/:patientId/procedures/:procedureId/tab/:tabId'
                component={PatientDetails}
              />
              <MedStaffAuthorizedRoute
                path='/patients/:patientId/procedures/:procedureId/conversations/:conversationId'
                component={Conversations}
              />
              <MedStaffAuthorizedRoute
                path='/search/patients'
                component={PatientSearch}
              />
              <MedStaffAuthorizedRoute
                path='/lookup/:key/:category'
                component={LookupSearch}
              />

              <MedStaffAuthorizedRoute
                path='/manage-patient-advocates'
                component={ManagePatientAdvocate}
              />
              <MedStaffAuthorizedRoute
                path='/manage-patient-advocate/:patientAdvocateId/patients'
                component={ManagePatients}
              />
              <MedStaffAuthorizedRoute
                path='/patients/:patientId/procedures/:procedureId/communication-log/:logId'
                component={NoteDetails}
              />
              <MedStaffAuthorizedRoute
                exact
                path='/surveys'
                component={DepartmentSurveys}
              />
              <MedStaffAuthorizedRoute
                exact
                path='/unSupportedPageView'
                component={UnSupportedPageView}
              />
              <MedStaffAuthorizedRoute
                path='/reports/:tabId'
                component={Reports}
              />
              <MedStaffAuthorizedRoute
                path='/patients/:patientId/procedures/:procedureId/surveys/:eventId'
                exact
                component={SurveyFeedback}
              />

              <MedStaffAuthorizedRoute
                path='/patients/payments'
                exact
                component={PaymentsPage}
              />

              <SuperUserAuthorizedRoute
                exact
                path='/admin/tab/:tabId'
                component={AdminPage}
              />
              <AuthenticatedRoute path='/profile' component={Profile} />
              <AuthenticatedRoute
                path='/change-password'
                component={ChangePassword}
              />
              <AuthenticatedRoute
                path='/notifications'
                component={Notifications}
              />
              <AuthenticatedRoute
                path='/reset-password'
                component={ForceResetPassword}
              />

              <Route
                path='/summary/quickview'
                component={ProcedureSummaryQuickView}
              />
              <Route path='/outcomes-card' component={OutcomesCardSummary} />
              <Route exact path='/logout' component={Logout} />
              <Route path='/unauthorized-access' component={UnauthorizedUser} />
              <Route
                path='/not-found'
                render={() => {
                  if (isWebContainerRequest()) {
                    return (
                      <div className='share-report-mobile-fallback-wrapper'>
                        <div className='share-report-mobile-fallback-wrapper__error-text'>
                          <h4>Error</h4>
                          <h4>Something Went Wrong...</h4>
                        </div>
                      </div>
                    );
                  }
                  return <NotFoundPage />;
                }}
              />
              <Route exact path='/saml/verify_code' component={SamlCallback} />
              <Redirect to='/not-found' />
            </Switch>
          </div>
        </Sentry.ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isRequestMultiAuthenticated:
    state.MultiAuthenticatedRequestReducer.isRequestMultiAuthenticated,
  isSuperUser: state.currentUser.attributes.hasSuperUserPrivileges,
});

App.propTypes = {
  isRequestMultiAuthenticated: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, null)(App));
