import React, { FC, useState, useEffect } from 'react';
import { PrimaryModal } from 'components/StyleGuideComponents/common/modals';
import { OTHER_REASON_CHARACTERS_LIMIT } from 'constants.js';
import validate, { clearErrorsForField } from 'common/validator';
import { PrimaryButton } from 'components/StyleGuideComponents/common/buttons';
import FormGenerator from 'components/FormGenerator';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  fetchDropdownOptionsForMultiAuthentication,
  resetDataForDoubleAuthetication,
} from 'components/MultiAuthenticatedRequest/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import {
  completeDeviceInterrogationRequest,
  fetchDeviceInterrogationOptionsRequest,
} from 'containers/PatientDetails/actions';
import variables from 'assets/stylesheets/_variables.scss';
import { getValidationsObject } from 'utils/stringUtils';
import { dispatchActionWithCallBack } from 'common/actions';

type Props = {
  showModal: boolean;
  setShowModal: (arg0: boolean) => void;
  userProcedureId: number;
};

type Reason = {
  label: string;
  value: string;
  isDefault: boolean;
  isCommentAllowed: boolean;
};

type DeviceInterrogationEvent = {
  label: string;
  value: string;
  disabled: boolean;
  submitted_at: string;
};

type FormData = {
  password: string;
  reason: Reason | null;
  otherReasonText: string;
  deviceInterrogationEvent: DeviceInterrogationEvent | null;
};

type Errors = Record<string, string>;

const DeviceInterrogationMultiAuth: FC<Props> = props => {
  const { showModal, setShowModal, userProcedureId } = props;
  const dispatch = useAppDispatch();
  const { reasonOptions } = useAppSelector(
    store => store.MultiAuthenticatedRequestReducer
  );
  const { deviceInterrogationEvents } = useAppSelector(
    store => store.deviceInterrogationReducer
  );

  const initialFormData = {
    password: '',
    reason: null,
    otherReasonText: '',
    deviceInterrogationEvent: null,
  };

  const [isCommentAllowed, setIsCommentAllowed] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    reasonOptions.forEach((reason: Reason) => {
      if (reason.isDefault) {
        setFormData({
          ...formData,
          reason,
        });
      }
    });
  }, [reasonOptions]);

  useEffect(() => {
    if (showModal) {
      dispatch(
        fetchDropdownOptionsForMultiAuthentication(
          SecureCodes.COMPLETE_DEVICE_INTERROGATION
        )
      );
    }
  }, [showModal]);

  const resetDataAfterModalHide = () => {
    setFormData(initialFormData);
    setIsCommentAllowed(false);
    setErrors({});
    dispatch(resetDataForDoubleAuthetication());
    setShowModal(false);
  };

  const config = [
    {
      id: 'deviceInterrogationEvent',
      input_type: 'mui_single_select',
      name: 'deviceInterrogationEvent',
      label: 'Event',
      placeholder: 'Select Event',
      validation: [{ rule: 'isRequired', message: 'Event is required' }],
      value: formData.deviceInterrogationEvent,
      options: deviceInterrogationEvents,
      isInlineLabelNeeded: false,
      required: true,
      visible: true,
    },
    {
      id: 'password',
      input_type: 'mui_password',
      name: 'password',
      autoComplete: false,
      label: 'Password',
      placeholder: 'Enter Password',
      validation: [{ rule: 'isRequired', message: 'Password is required' }],
      value: formData.password,
      isInlineLabelNeeded: false,
      required: true,
      visible: true,
    },
    {
      id: 'reason',
      input_type: 'mui_single_select',
      name: 'reason',
      label: 'Reason',
      placeholder: 'Select Reason',
      validation: [{ rule: 'isRequired', message: 'Reason is required' }],
      value: formData.reason,
      options: reasonOptions,
      isInlineLabelNeeded: false,
      required: true,
      visible: true,
    },
    {
      id: 'otherReasonText',
      input_type: 'textarea',
      name: 'otherReasonText',
      label: 'Enter Reason',
      type: 'text',
      placeholder: 'Type here...',
      maxLength: OTHER_REASON_CHARACTERS_LIMIT,
      value: formData.otherReasonText,
      validation: [{ rule: 'isRequired', message: 'Reason is required' }],
      visible: isCommentAllowed,
      required: true,
    },
  ];

  const onFormValidationFailure = (errorsInForm: Errors) => {
    setErrors(errorsInForm);
  };

  const onFormValidationSuccess = () => {
    setErrors({});
    let paramReason = formData.reason?.label;
    if (isCommentAllowed)
      paramReason = `${formData.reason?.label}-${formData.otherReasonText}`;

    const payload = {
      user_procedure_id: userProcedureId,
      event_id: formData.deviceInterrogationEvent?.value,
      reason: paramReason,
      current_password: formData.password,
    };

    dispatch(
      dispatchActionWithCallBack(
        completeDeviceInterrogationRequest,
        { params: payload },
        () => {
          resetDataAfterModalHide();
          dispatch(
            fetchDeviceInterrogationOptionsRequest({
              user_procedure_id: userProcedureId,
            })
          );
        }
      )
    );
  };

  const submitForm = (e: Event) => {
    e.preventDefault();
    validate(
      getValidationsObject(config),
      formData,
      onFormValidationFailure,
      onFormValidationSuccess
    );
  };

  return (
    <PrimaryModal
      show={showModal}
      onHide={resetDataAfterModalHide}
      bodyHeader={{
        text: (
          <span style={{ color: variables.colorBlack, fontWeight: 500 }}>
            Mark Device Interrogation
          </span>
        ),
      }}
      bodyContent={{
        text: (
          <div>
            <p
              style={{
                textAlign: 'justify',
                fontWeight: 400,
              }}
            >
              By marking this as complete, you will trigger a payment request
              for completing this in-clinic device interrogation milestone.
            </p>

            <FormGenerator
              id='re-auth-form'
              className='re-auth-form'
              formConfig={config}
              onChange={(
                key: string,
                value: Reason | DeviceInterrogationEvent | string
              ) => {
                clearErrorsForField(errors, key);
                if (key === 'reason') {
                  const reasonValue = value as Reason;
                  setIsCommentAllowed(reasonValue.isCommentAllowed);
                  setFormData({ ...formData, [key]: reasonValue });
                  clearErrorsForField(errors, 'otherReasonText');
                } else setFormData({ ...formData, [key]: value });
              }}
              label={formData.reason || ''}
              errors={errors}
            />
          </div>
        ),
        align: 'left',
      }}
      modalStyle={{
        width: '500px',
      }}
      buttons={[
        <PrimaryButton
          type='submit'
          form='re-auth-form'
          onClick={submitForm}
          key='primaryButton'
        >
          Submit Password
        </PrimaryButton>,
      ]}
    />
  );
};

export default DeviceInterrogationMultiAuth;
