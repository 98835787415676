import {
  FETCH_LATEST_REPORTS_DATA,
  FETCH_ARCHIVED_REPORTS_DATA,
  DOWNLOAD_REPORT,
  REPORTS_AUDIT_RAILS,
  EXPORT_REPORT_AUDIT_TRAILS,
  FETCH_REPORT_GENERATION_INFO,
  GENERATE_DATA_REPORT,
} from './constants';
import axios from './base';

export const fetchLatestReportsData = (report_type: string) =>
  axios.get(FETCH_LATEST_REPORTS_DATA, { params: { report_type } });

export const fetchArchivedReportsData = (
  report_type: string,
  pageNumber: number
) =>
  axios.get(FETCH_ARCHIVED_REPORTS_DATA, {
    params: { report_type, page: pageNumber },
  });

export const downloadReport = params => axios.post(DOWNLOAD_REPORT, params);

export const fetchReportAuditTrail = () => axios.get(REPORTS_AUDIT_RAILS);

export const exportReportsAuditTrailsCsv = () =>
  axios.post(EXPORT_REPORT_AUDIT_TRAILS);

export const fetchReportGenerationInfo = () =>
  axios.get(FETCH_REPORT_GENERATION_INFO);

export const generateDataReport = params =>
  axios.post(GENERATE_DATA_REPORT, params);
