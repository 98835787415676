import React, { useEffect, useState } from 'react';
import './index.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import TabMenu from 'components/StyleGuideComponents/TabMenu/TabMenu';
import { useHistory, useParams } from 'react-router-dom';
import DashboardNavigationBar from 'components/DashboardNavigationBar';
import {
  getLatestReportsData,
  getArchivedReportsData,
  clearReportDataDetails,
  getReportAuditTrails,
} from './actions';
import ReportsData from './components/ReportsData';
import ReportAuditTrails from './components/ReportAuditTrails';

interface TabParams {
  tabId: string;
}

const Reports: React.FC = () => {
  const reportsData = useAppSelector(store => store.DataReportsReducer);
  const { auditTrails, auditTrailsHeaders } = reportsData;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { tabId } = useParams<TabParams>();
  const [activeTab, setActiveTab] = useState<number>(parseInt(tabId, 10));

  const tabs = [
    {
      id: 1,
      text: 'Safety Review Data',
      apiResponseKey: 'safetyReviewData',
      reportType: 'SafetyDataReport',
    },
    {
      id: 2,
      text: 'Full Data Extract',
      apiResponseKey: 'fullDataExtract',
      reportType: 'FullDataReport',
    },
    {
      id: 3,
      text: 'Audit Trail',
      apiResponseKey: 'auditTrail',
    },
  ];

  const onTabChange = (id: number) => {
    history.push(`${id}`);
    setActiveTab(id);
  };

  useEffect(() => {
    setActiveTab(parseInt(tabId, 10));
    const selectedTab = tabs.find(tab => tab.id.toString() === tabId);
    if (selectedTab?.apiResponseKey === 'auditTrail') {
      dispatch(getReportAuditTrails());
    } else {
      dispatch(getLatestReportsData(selectedTab?.reportType || ''));
      dispatch(getArchivedReportsData(selectedTab?.reportType || '', 1));
    }

    return () => {
      dispatch(clearReportDataDetails(selectedTab?.apiResponseKey || ''));
    };
  }, [tabId]);

  const onPageChange = (pageNumber: number) => {
    const selectedTab = tabs.find(tab => tab.id === activeTab);
    dispatch(getArchivedReportsData(selectedTab?.reportType || '', pageNumber));
  };

  const getNextDataReportDate = () => {
    const selectedTab = tabs.find(tab => tab.id === activeTab);
    if (!selectedTab) return null;
    const data = reportsData[selectedTab?.apiResponseKey];
    return data?.latest.nextReportGenerationTime;
  };

  const getSelectedTabReportsData = () => {
    const selectedTab = tabs.find(tab => tab.id === activeTab);
    if (!selectedTab) return null;
    const data = reportsData[selectedTab?.apiResponseKey];
    return data || null;
  };

  const getReportName = () => {
    const selectedTab = tabs.find(tab => tab.id === activeTab);
    return selectedTab?.text || '';
  };

  return (
    <div className='report-wrapper'>
      <DashboardNavigationBar activeTab='reports' />
      <div className='tabs-wrapper'>
        <TabMenu tabs={tabs} activeTab={activeTab} onTabChange={onTabChange} />
      </div>

      <div className='reports-data'>
        {activeTab === 3 ? (
          <ReportAuditTrails
            auditTrails={auditTrails}
            auditTrailsHeaders={auditTrailsHeaders}
          />
        ) : (
          <ReportsData
            reportName={getReportName()}
            nextDataReportDate={getNextDataReportDate()}
            reportsData={getSelectedTabReportsData()}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </div>
  );
};
export default Reports;
