import { isiOS } from 'utils/common';

export const USER_TYPE_PATIENT = 'Patient';

export const USER_TYPE_PROVIDER = 'Provider';

export const USER_ROLE_RA = 'RA';

export const USER_TYPE_PATIENT_ADVOCATE = 'PatientAdvocate';

export const RESET_GLOBAL_REDUCERS = 'RESET_GLOBAL_REDUCERS';

export const SEARCHABLE_QUESTION_QUERY_LENGTH = 3;

export const IMAGE_FILE_FORMATS = [
  'image/jpg',
  'image/bmp',
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/tiff',
];

export const DOC_FILE_FORMATS = [
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/x-msi',
];

export const AUDIO_FORMATS = [
  'audio/x-ms-wma',
  'audio/mpeg',
  'audio/mp4',
  'audio/wavpack',
];

export const MS_DOC_EXTENSIONS = [
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
];

export const ALL_FILE_FORMATS = IMAGE_FILE_FORMATS.concat(DOC_FILE_FORMATS)
  .concat(AUDIO_FORMATS)
  .concat(MS_DOC_EXTENSIONS);

export const COMMUNICATION_LOG_SUPPORTED_FILE_FORMATS = isiOS
  ? '*/*'
  : ALL_FILE_FORMATS.toString();

export const CONSENT_SUPPORTED_FILE_FORMATS = 'application/pdf';

export const DATE_FORMAT = 'MM-DD-YYYY';

export const RESEND_OTP_TIME_IN_SECONDS = 60;

export const NOTES_EDIT_CHARACTERS_LIMIT = 100;

export const DISPLAY_DATE_FORMAT = 'MMMM DD, YYYY';

export const DISPLAY_TIME_FORMAT = 'hh:mm A z';

export const DISPLAY_TO_BE_DECIDED_STRING = 'TBD';

export const EDIT_APPOINTMENT_DATE_FORMAT = 'MMM DD, YYYY';

export const APPOINTMENT_TIME_FORMAT = 'hh:mm A';

export const PHONE_INPUT_MASK = '+1 999 999 9999';

export const ZIP_INPUT_REGEX = '[0-9|-]';

export const OTHER_REASON_CHARACTERS_LIMIT = 100;

export const OTP_CHARACTERS_LIMIT = 6;

export const isSaveFilterEnabled =
  process.env.REACT_APP_SHOW_SAVE_FILTERS_ON_PATIENT_SEARCH === 'true';
export const SHOW_OUTCOMES_CHART =
  process.env.REACT_APP_SHOW_OUTCOME_CHARTS === 'true';
export const DISABLE_PATIENT_MESSAGES =
  process.env.REACT_APP_DISABLE_PATIENT_MESSAGES === 'true';
export const DISABLE_CLINICIAN_FEATURES =
  process.env.REACT_APP_DISABLE_CLINICIAN_FEATURES === 'true';
export const SHOW_TERMS_AND_CONDITIONS_MODAL =
  process.env.REACT_APP_SHOW_TERMS_AND_CONDITIONS_MODAL === 'true';
export const DISABLE_ADD_VISIT =
  process.env.REACT_APP_DISABLE_ADD_VISIT === 'true';
export const SHOW_TEAM_MEMBER_SECTION =
  process.env.REACT_APP_REMOVE_TEAM_MEMBER_SECTION === 'true';
export const DISABLE_AGGREGATE_OUTCOMES =
  process.env.REACT_APP_DISABLE_AGGREGATE_OUTCOMES === 'true';
export const EDIT_COUNTER_SIGNEE_DISCLAIMER_TEXT =
  '*Counter signer cannot be edited as there is a consent signature pending from either parties.';

export const ACCESS_CONTROL_KEYS = {
  PATIENT_DEMOGRAPHICS: 'patientDemographics',
  VISITS: 'appointments',
  TASKS: 'tasks',
  SURVEYS: 'surveys',
  MESSAGES: 'messages',
  DIARY: 'diary',
  PAPER_CONSENT: 'paperConsent',
  CONSENT: 'consent',
  AUDIT_TRAIL: 'auditTrail',
  NOTES: 'notes',
  REVOKE_ACCOUNT_DELETION: 'revokeAccountDeletion',
  EXIT_STUDY: 'exitStudy',
  PHASE_CHANGE: 'phaseChange',
  TAG_FOR_FOLLOW_UP: 'tagForFollowUp',
  OUTCOMES_REPORTS: 'outcomesReports',
  PAYMENT_HISTORY: 'paymentHistory',
  DEVICE_INTERROGATION: 'deviceInterrogation',
  DATA_REPORTS: 'dataReports',
};

export const USER_TYPES = {
  MASTER: 'MASTER',
  SUPPORT: 'SUPPORT',
};

export const DEFAULT_PAGINATION_LIMIT = 10;
