/*
 *
 * PatientDetailsPage : Appointment Tab actions
 *
 27 Oct 2021 : Fix for [EDWS-114] & [EDWS-288] : Added dispatch action flagPermissionError 
               for showing Toaster when access permission for Camera and/or Microphone is 
               not granted by user.
 */

import { resetDataForDoubleAuthetication } from 'components/MultiAuthenticatedRequest/actions';
import {
  setAddVisitStarted,
  setEditVisitStarted,
  setEditVisitNoteStarted,
} from 'common/actions';
import { showToaster } from 'common/toasterActions';
import {
  fetchDeviceInterrogationOptionsRequest,
  getPatientInfo,
} from 'containers/PatientDetails/actions';
import {
  getPatientAppointments as ApiCall,
  getEventQuestions as getQuestions,
  updateAppointment as updateEvent,
  createAppointment as createAppointmentService,
  deleteAppointment as deleteAppointmentService,
  updateAppointmentNotes as updateNotes,
  skipAppointment,
  sendTeleCallReminder,
} from 'services/patientDetails';

import * as Constants from './constants';

export const getPatienAppointmentsRequestSent = () => ({
  type: Constants.GET_APPOINTMENTS_REQUEST_SENT,
});

export const getPatientAppointmentsRequestSucceeded = data => ({
  type: Constants.GET_APPOINTMENTS_REQUEST_SUCCEED,
  appointments: data,
});

export const getPatientAppointmentsRequestFailed = () => ({
  type: Constants.GET_APPOINTMENTS_REQUEST_FAILED,
});

export const getEventQuestionsRequestSent = () => ({
  type: Constants.GET_EVENT_QUESTIONS_REQUEST_SENT,
});

export const getEventQuestionsRequestSucceeded = data => ({
  type: Constants.GET_EVENT_QUESTIONS_REQUEST_SUCCEED,
  data,
});

export const getEventQuestionsRequestFailed = () => ({
  type: Constants.GET_EVENT_QUESTIONS_REQUEST_FAILED,
});

export const updateAppointmentRequestSent = () => ({
  type: Constants.UPDATE_APPOINTMENT_REQUEST_SENT,
});

export const updateAppointmentRequestSucceeded = data => ({
  type: Constants.UPDATE_APPOINTMENT_REQUEST_SUCCEED,
  data,
});

export const updateAppointmentRequestFailed = () => ({
  type: Constants.UPDATE_APPOINTMENT_REQUEST_FAILED,
});

export const createAppointmentRequestSent = () => ({
  type: Constants.CREATE_APPOINTMENT_REQUEST_SENT,
});

export const createAppointmentRequestSucceeded = data => ({
  type: Constants.CREATE_APPOINTMENT_REQUEST_SUCCEED,
  data,
});

export const createAppointmentRequestFailed = () => ({
  type: Constants.CREATE_APPOINTMENT_REQUEST_FAILED,
});

export const deleteAppointmentRequestSent = () => ({
  type: Constants.DELETE_APPOINTMENT_REQUEST_SENT,
});

export const deleteAppointmentRequestSucceeded = eventId => ({
  type: Constants.DELETE_APPOINTMENT_REQUEST_SUCCEED,
  eventId,
});

export const deleteAppointmentRequestFailed = () => ({
  type: Constants.DELETE_APPOINTMENT_REQUEST_FAILED,
});

export const skipAppointmentRequestSent = () => ({
  type: Constants.SKIP_APPOINTMENT_REQUEST_SENT,
});

export const skipAppointmentRequestSucceeded = eventId => ({
  type: Constants.SKIP_APPOINTMENT_REQUEST_SUCCEED,
  eventId,
});

export const skipAppointmentRequestFailed = () => ({
  type: Constants.SKIP_APPOINTMENT_REQUEST_FAILED,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getPatientAppointments =
  (id, compositeProcedureId, procedurePhaseId) => dispatch => {
    dispatch(getPatienAppointmentsRequestSent());
    ApiCall(id, compositeProcedureId, procedurePhaseId)
      .then(response => {
        dispatch(getPatientAppointmentsRequestSucceeded(response.data));
      })
      .catch(error => {
        dispatch(getPatientAppointmentsRequestFailed());
        dispatch(
          showToaster({ type: 'error', message: error.response.data.error })
        );
      });
  };

export const getEventQuestions = eventId => dispatch => {
  dispatch(getEventQuestionsRequestSent());
  getQuestions(eventId)
    .then(response => {
      dispatch(getEventQuestionsRequestSucceeded(response.data));
    })
    .catch(error => {
      dispatch(getEventQuestionsRequestFailed());
      dispatch(
        showToaster({ type: 'error', message: error.response.data.error })
      );
    });
};

export const updateAppointment = params => dispatch => {
  dispatch(updateAppointmentRequestSent());
  updateEvent(params)
    .then(response => {
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: 'Appointment updated successfully' }));
      dispatch(setEditVisitStarted());
      dispatch(updateAppointmentRequestSucceeded(response.data));
      dispatch(
        getPatientAppointments(params.patientId, params.compositeProcedureId)
      );
      if (params.procedureId) {
        dispatch(getPatientInfo(params.patientId, params.procedureId));
        dispatch(
          fetchDeviceInterrogationOptionsRequest({
            user_procedure_id: params.procedureId,
          })
        );
      }
    })
    .catch(error => {
      dispatch(updateAppointmentRequestFailed(error.response.data));
      if (error.response.data.error)
        dispatch(
          showToaster({ type: 'error', message: error.response.data.error })
        );
    });
};

export const createAppointment = params => dispatch => {
  dispatch(createAppointmentRequestSent());
  createAppointmentService(params)
    .then(response => {
      dispatch(createAppointmentRequestSucceeded(response.data));
      dispatch(resetDataForDoubleAuthetication());
      dispatch(setAddVisitStarted());
      dispatch(
        getPatientAppointments(params.patientId, params.compositeProcedureId)
      );
      dispatch(getPatientInfo(params.patientId, params.user_procedure_id));
      dispatch(showToaster({ message: 'Appointment created successfully' }));
    })
    .catch(error => {
      dispatch(createAppointmentRequestFailed(error.response.data));
      if (error.response.data.error)
        dispatch(
          showToaster({ type: 'error', message: error.response.data.error })
        );
    });
};

export const flagPermissionError = () => dispatch => {
  try {
    dispatch(
      showToaster({
        type: 'error',
        message: 'Audio & Video Permission is required to Join Call',
      })
    );
  } catch (error) {
    dispatch(
      showToaster({ type: 'error', message: error.response.data.error })
    );
  }
};

export const deleteAppointment = params => dispatch => {
  dispatch(deleteAppointmentRequestSent());
  deleteAppointmentService(params)
    .then(() => {
      dispatch(deleteAppointmentRequestSucceeded(params.appointment_id));
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: 'Appointment deleted successfully' }));
    })
    .catch(error => {
      dispatch(deleteAppointmentRequestFailed());
      if (error.response.data.error)
        dispatch(
          showToaster({ type: 'error', message: error.response.data.error })
        );
    });
};

export const skipAppointmentAction = (eventId, password) => dispatch => {
  dispatch(skipAppointmentRequestSent());
  skipAppointment(eventId, password)
    .then(() => {
      dispatch(skipAppointmentRequestSucceeded(eventId));
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: 'Appointment skipped successfully' }));
    })
    .catch(error => {
      dispatch(skipAppointmentRequestFailed());
      if (error.response.data.error)
        dispatch(
          showToaster({ type: 'error', message: error.response.data.error })
        );
    });
};

export const sendTelemedicineCallReminderSent = () => ({
  type: Constants.POST_TELEMEDICINECALL_REMINDER_SENT,
});

export const sendTelemedicineCallReminderSucceeded = data => ({
  type: Constants.POST_TELEMEDICINECALL_REMINDER_SUCCEED,
  data,
});

export const sendTelemedicineCallReminderFailed = () => ({
  type: Constants.POST_TELEMEDICINECALL_REMINDER_FAILED,
});

export const sendTelemedicineCallReminderAction =
  (id, pId, compositeProcedureId) => dispatch => {
    dispatch(sendTelemedicineCallReminderSent());
    sendTeleCallReminder(id, pId)
      .then(response => {
        dispatch(showToaster({ message: 'Reminder sent successfully' }));
        dispatch(sendTelemedicineCallReminderSucceeded(response.data));
        dispatch(getPatientAppointments(pId, compositeProcedureId));
      })
      .catch(error => {
        dispatch(sendTelemedicineCallReminderFailed());
        dispatch(
          showToaster({ type: 'error', message: error.response.data.error })
        );
      });
  };

export const updateAppointmentNotes = params => dispatch => {
  dispatch(updateAppointmentRequestSent());
  updateNotes(params)
    .then(response => {
      dispatch(
        showToaster({ message: 'Appointment notes updated successfully' })
      );
      dispatch(setEditVisitNoteStarted());
      dispatch(resetDataForDoubleAuthetication());
      dispatch(updateAppointmentRequestSucceeded(response.data));
      dispatch(
        getPatientAppointments(params.patientId, params.compositeProcedureId)
      );
      if (params.procedureId) {
        dispatch(getPatientInfo(params.patientId, params.procedureId));
      }
    })
    .catch(error => {
      dispatch(updateAppointmentRequestFailed(error.response.data));
      if (error.response.data.error)
        dispatch(
          showToaster({ type: 'error', message: error.response.data.error })
        );
    });
};
