import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { snakeCase } from 'change-case-object';
import { dispatchActionWithCallBack } from 'common/actions';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import FormDataPreview from 'containers/AdminPage/FormDataPreview';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import {
  fetchUsersListOnAdminPageRequest,
  createUserRequest,
  updateUserRequest,
} from '../action';

const AddUserFormPreview = props => {
  const dispatch = useDispatch();

  const userAdminData = useSelector(store => store.userAdminReducer);

  const makeParamsReady = data => {
    const params = {};
    Object.keys(data).forEach(key => {
      if (key === 'contactNumber') {
        params[snakeCase(key)] = userAdminData[key]
          ?.replace('+1', '')
          ?.replace(/ /g, '');
      } else if (key === 'primaryPhysicians') {
        if (
          !(
            userAdminData.userType?.label?.toUpperCase() === 'SUPPORT' &&
            userAdminData.type?.value?.toUpperCase() === 'PROVIDER'
          )
        )
          params[snakeCase(key)] = [];
        else params[snakeCase(key)] = userAdminData[key].map(phy => phy.value);
      } else if (key === 'primaryAdvocates') {
        if (
          !(
            userAdminData.userType?.label?.toUpperCase() === 'SUPPORT' &&
            userAdminData.type?.value?.toUpperCase() === 'PATIENTADVOCATE'
          )
        )
          params[snakeCase(key)] = [];
        else params[snakeCase(key)] = userAdminData[key].map(phy => phy.value);
      } else if (key === 'userLocations')
        params[snakeCase(key)] = userAdminData[key].map(loc => loc.value);
      else if (key === 'type')
        params[snakeCase(key)] =
          userAdminData[key].value.charAt(0).toUpperCase() +
          userAdminData[key].value.slice(1);
      else if (
        key === 'type' ||
        key === 'userType' ||
        key === 'userRole' ||
        key === 'userDepartment'
      )
        params[snakeCase(key)] = userAdminData[key].value;
      else if (
        key === 'firstName' ||
        key === 'lastName' ||
        key === 'email' ||
        key === 'isSuperUser' ||
        key === 'attachPatients' ||
        key === 'allowSsoLogin' ||
        key === 'shouldInvite' ||
        key === 'isApprovedCounterSigner'
      )
        params[snakeCase(key)] = userAdminData[key];
    });
    params.needs_reason = true;
    if (userAdminData.selectedUserId)
      params.secure_action_code = SecureCodes.EDIT_USER;
    else params.secure_action_code = SecureCodes.ADD_USER;
    return params;
  };

  const onSubmit = () => {
    const { handleModalHide } = props;
    const userData = makeParamsReady(userAdminData);
    const action = userAdminData.selectedUserId
      ? updateUserRequest
      : createUserRequest;
    dispatch(
      dispatchActionWithCallBack(
        secureActionWithDoubleAuthentication,
        {
          action,
          params: { id: userAdminData.selectedUserId, ...userData },
        },
        () => {
          handleModalHide();
          dispatch(
            fetchUsersListOnAdminPageRequest({
              name: userAdminData.filterNameOrEmail,
              page: userAdminData.page,
            })
          );
        }
      )
    );
  };

  const dataToShow = [
    {
      title: 'Personal Information',
      editStep: 1,
      columnsData: [
        {
          label: 'FIRST NAME',
          value: userAdminData.firstName,
          visible: userAdminData.firstName,
          editable: true,
        },
        {
          label: 'LAST NAME',
          value: userAdminData.lastName,
          visible: userAdminData.lastName,
          editable: true,
        },
        {
          label: 'EMAIL',
          value: userAdminData.email,
          visible: userAdminData.email,
          editable: true,
        },
        {
          label: 'CONTACT NUMBER',
          value: userAdminData.contactNumber,
          visible: userAdminData.contactNumber,
          editable: true,
        },
      ],
    },
    {
      title: 'Position Details',
      editStep: 2,
      columnsData: [
        {
          label: 'DEPARTMENT',
          value: userAdminData.userDepartment?.label,
          visible: !!userAdminData.userDepartment?.value,
          editable: false,
        },
        {
          label: 'TYPE',
          value: userAdminData.type?.label,
          visible: !!userAdminData.type?.value,
          editable: false,
        },
        {
          label: 'USER CATEGORY',
          value: userAdminData.userType?.label,
          visible: userAdminData.userType?.value !== null,
          editable: false,
        },
        {
          label: 'USER ROLE',
          value: userAdminData.userRole?.label,
          visible: !!userAdminData.userRole?.value,
          editable: false,
        },
        {
          label: 'PRIMARY PHYSICIANS',
          value: userAdminData.primaryPhysicians
            .map(physician => physician.label)
            .join(', '),
          visible:
            userAdminData.type?.value === 'Provider' &&
            userAdminData.userType?.value === 1,
          editable: false,
        },
        {
          label: 'PRIMARY ADVOCATES',
          value: userAdminData.primaryAdvocates
            .map(advocate => advocate.label)
            .join(', '),
          visible:
            userAdminData.type?.value === 'PatientAdvocate' &&
            userAdminData.userType?.value === 1,
          editable: false,
        },
      ],
    },
    {
      title: 'Role Details',
      editStep: 3,
      columnsData: [
        {
          label: 'LOCATIONS',
          value: userAdminData.userLocations
            .map(location => location.label)
            .join(', '),
          visible: userAdminData.userLocations.length,
          editable: true,
        },
        {
          label: 'IS USER A SUPER USER?',
          value: userAdminData.isSuperUser ? 'Yes' : 'No',
          visible: true,
        },
        {
          label: 'SHOULD ATTACH PATIENTS?',
          value: userAdminData.attachPatients ? 'Yes' : 'No',
          visible: true,
          editable: true,
        },
        {
          label: 'SHOULD INVITE',
          value: userAdminData.shouldInvite ? 'Yes' : 'No',
          visible: true,
          editable: false,
        },
        {
          label: 'IS APPROVED COUNTERSIGNER',
          value: userAdminData.isApprovedCounterSigner ? 'Yes' : 'No',
          visible: true,
          editable: false,
        },
        {
          label: 'SHOULD SSO LOGIN BE ALLOWED?',
          value: userAdminData.allowSsoLogin ? 'Yes' : 'No',
          visible: true,
          editable: false,
        },
      ],
    },
  ];

  return (
    <FormDataPreview dataToShow={dataToShow} {...props} onSubmit={onSubmit} />
  );
};

AddUserFormPreview.propTypes = {
  handleModalHide: PropTypes.func.isRequired,
};

export default AddUserFormPreview;
