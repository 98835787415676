import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormGenerator from 'components/FormGenerator';
import validate, { clearErrorsForField } from 'common/validator';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/StyleGuideComponents/common/buttons';
import { getValidationsObject } from 'utils/stringUtils';
import PropTypes from 'prop-types';
import { saveAddUserFormData, updateUserAdminValue } from '../action';
import { isDeidentifiedAccessUserRole } from '../services';

const AddUserStepThreeForm = props => {
  const dispatch = useDispatch();

  const userAdminData = useSelector(store => store.userAdminReducer);
  const adminData = useSelector(store => store.AdminReducer);

  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState({
    userLocations: [...userAdminData.userLocations],
    isSuperUser: userAdminData.isSuperUser,
    attachPatients: userAdminData.attachPatients,
    shouldInvite: userAdminData.shouldInvite,
  });

  useEffect(() => {
    setUserData({
      ...userData,
      userLocations: userAdminData.userLocations,
      isSuperUser: userAdminData.isSuperUser,
      attachPatients: userAdminData.attachPatients,
      shouldInvite: userAdminData.shouldInvite,
      isApprovedCounterSigner: userAdminData.isApprovedCounterSigner,
    });
  }, [
    userAdminData.userLocations,
    userAdminData.isSuperUser,
    userAdminData.attachPatients,
    userAdminData.shouldInvite,
    userAdminData.isApprovedCounterSigner,
  ]);

  useEffect(() => {
    if (userAdminData.userType?.value === 1)
      dispatch(updateUserAdminValue('isSuperUser', false));
    if (
      userAdminData.userType?.value === 1 ||
      userAdminData.type?.value === 'Provider'
    )
      dispatch(updateUserAdminValue('attachPatients', false));
  }, [userAdminData.type, userAdminData.userType]);

  const config = [
    {
      id: 1,
      input_type: 'multi_select',
      label: 'Select Location ( optional )',
      placeholder: 'Select Location',
      name: 'userLocations',

      options: adminData.locations,
      visible: true,
      value: userData.userLocations,
      disabled:
        userData.isSuperUser ||
        userAdminData.userType?.label === 'support' ||
        userAdminData.userRole?.label === 'CRC' ||
        isDeidentifiedAccessUserRole(
          adminData.deidentifiedAccessUserRoles,
          userAdminData.userRole?.value
        ),
    },
    {
      id: 2,
      input_type: 'checkbox',
      label: 'Is Super User?',
      name: 'isSuperUser',
      disabled:
        userAdminData.selectedUserId ||
        userAdminData.userType?.value !== 0 ||
        isDeidentifiedAccessUserRole(
          adminData.deidentifiedAccessUserRoles,
          userAdminData.userRole?.value
        ),
      value: userData.isSuperUser,
      visible: true,
    },
    {
      id: 3,
      input_type: 'checkbox',
      label: 'should attach patients?',
      name: 'attachPatients',
      disabled:
        userAdminData.userType?.value === 1 ||
        userData.userLocations?.length === 0,
      value: userData.attachPatients,
      visible: true,
    },
    {
      id: 4,
      input_type: 'checkbox',
      label: 'should invite?',
      name: 'shouldInvite',
      value: userData.shouldInvite,
      disabled: userAdminData.selectedUserId,
      visible: true,
    },
    {
      id: 5,
      input_type: 'checkbox',
      label: 'is approved counter signer?',
      name: 'isApprovedCounterSigner',
      value: userData.isApprovedCounterSigner,
      visible:
        !isDeidentifiedAccessUserRole(
          adminData.deidentifiedAccessUserRoles,
          userAdminData.userRole?.value
        ) && userAdminData.type?.value === 'Provider',
    },
  ];

  const { onContinue, onBack, step } = props;

  const onSubmit = () => {
    validate(
      getValidationsObject(config),
      userData,
      errorsInForm => {
        setErrors(errorsInForm);
      },
      () => {
        dispatch(saveAddUserFormData(userData));
        onContinue(step + 1);
      }
    );
  };

  return (
    <FormGenerator
      id='addUserStepThreeForm'
      className='add-edit-form'
      formConfig={config}
      errors={errors}
      onChange={(key, value) => {
        clearErrorsForField(errors, key);
        const data = { ...userData, [key]: value };
        if (key === 'isSuperUser' && value === true) data.userLocations = [];
        if (key === 'userLocations' && value?.length === 0)
          data.attachPatients = false;
        setUserData({ ...data });
      }}
      onSubmit={() => {
        onSubmit();
      }}
      {...props}
      buttons={[
        <SecondaryButton
          style={{ width: '176px' }}
          onClick={() => {
            dispatch(saveAddUserFormData(userData));
            onBack(step);
          }}
          key='secondaryButton'
        >
          <span>Back</span>
        </SecondaryButton>,
        <PrimaryButton
          form='addUserStepThreeForm'
          type='submit'
          style={{ width: '176px' }}
          onClick={() => {
            onSubmit();
          }}
          key='primaryButton'
        >
          <span>Continue</span>
        </PrimaryButton>,
      ]}
    />
  );
};

AddUserStepThreeForm.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

export default AddUserStepThreeForm;
