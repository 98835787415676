import React from 'react';
import { PaginationType } from 'types';
import ReportTable from './ReportTable';
import { ReportDataType } from '../types';

interface ReportsDataProps {
  nextDataReportDate: Date | null;
  reportName: string;
  reportsData: {
    latest: ReportDataType;
    archived: ReportDataType[];
    pagination: PaginationType;
  };
  onPageChange: (pageNumber: number) => void;
}

const ReportsData: React.FC<ReportsDataProps> = ({
  nextDataReportDate,
  reportsData,
  reportName,
  onPageChange,
}) => {
  return (
    <>
      <div className='data-availability-text'>
        <p>PLEASE NOTE:</p>
        <p>All timestamps contained within the reports are in UTC timezone</p>
        {nextDataReportDate ? (
          <p>
            The next{' '}
            {`${reportName} will be available at ${nextDataReportDate}`}
          </p>
        ) : (
          <p>&nbsp;</p>
        )}
      </div>
      <div className='reports-data-table'>
        <ReportTable data={[reportsData.latest]} title='Latest report' />
        <ReportTable
          data={reportsData.archived}
          title='Archived report'
          pagination={reportsData.pagination}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
};
export default ReportsData;
