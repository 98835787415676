import validate from 'common/validator';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useState, useEffect } from 'react';
import { FetchPaymentHistoriesParamsType } from 'services/paymentHistory';
import { PaymentsDataObjectType, SubFilter } from './types';
import {
  clearAppliedFilters,
  exportCsvRequest,
  fetchPaymentFiltersRequest,
  fetchPaymentHistoriesRequest,
  refreshPaymentHistoryRequest,
} from './actions';

type FormDataType = {
  searchText: string;
};

type ErrorType = {
  [index: string]: string;
};

type StatusObjectType = {
  icon: {
    visible: boolean;
    showToolTip: boolean;
    value: string;
    disabled: boolean;
    title?: string;
  };
  value: string;
  onClick?: () => void;
};

type FormatedPaymentsDataObjectType =
  | PaymentsDataObjectType
  | {
      subjectStudyId: string[];
      status: StatusObjectType;
      greenphireStatus: StatusObjectType;
    };

const validationConfig = {
  fields: ['searchText'],
  rules: {
    searchText: [
      {
        rule: 'shouldExcludeChars',
        chars: ['%', '#'],
        message: 'Search text cannot have "%, #" characters',
      },
    ],
  },
};

const usePaymentsViewController = () => {
  const {
    paymentHistoryHeaders,
    paymentHistories,
    pagination,
    lastRefreshedAt,
    appliedFilters,
    appliedFiltersCount,
  } = useAppSelector(store => store.paymentsReducer);

  const initialFormData = {
    searchText: '',
  };
  const initialPageNo = 1;
  const initialErrors = {};

  const [formData, setFormData] = useState<FormDataType>(initialFormData);
  const [errors, setErrors] = useState<ErrorType>(initialErrors);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const resetState = () => {
    setFormData(initialFormData);
    setErrors(initialErrors);
  };

  const fetchPaymentHistories = (pageNo: number, term: string) => {
    let params = <FetchPaymentHistoriesParamsType>{};

    params.page = pageNo;
    if (term.length) params.term = term;

    if (appliedFiltersCount > 0) {
      const filterParams = <Record<string, string[]>>{};
      Object.keys(appliedFilters).forEach(key => {
        filterParams[key] = appliedFilters[key].map(
          (filter: SubFilter) => filter.key
        );
      });
      params = { ...params, ...filterParams };
    }

    dispatch(fetchPaymentHistoriesRequest(params));
  };

  const clearSearchText = () => {
    resetState();
    // passing initial values to below function instead of passing state values because set state is async
    fetchPaymentHistories(initialPageNo, initialFormData.searchText);
  };

  const onSubmit = () => {
    validate(
      validationConfig,
      formData,
      (errorsInForm: ErrorType) => setErrors(errorsInForm),
      () => fetchPaymentHistories(initialPageNo, formData.searchText)
    );
  };

  const formatTableData = (data: PaymentsDataObjectType[]) => {
    const dataToShow = data.map((item: PaymentsDataObjectType) => {
      const formatedData = <FormatedPaymentsDataObjectType>{ ...item };
      formatedData.subjectStudyId = [item.subjectStudyId, item.locationName];
      formatedData.status = {
        icon: {
          visible: !!item.error,
          showToolTip: true,
          value: 'error',
          disabled: true,
          title: item.error,
        },
        value: item.status,
      };
      formatedData.greenphireStatus = {
        icon: {
          visible: item.showCanBeRefreshed,
          showToolTip: false,
          value: 'sync',
          disabled: !item.canBeRefreshed,
          title: `Last refreshed at ${item.statusCheckLastAttemptedAt}`,
        },
        value: item.greenphireStatus,
        onClick: () => {
          dispatch(refreshPaymentHistoryRequest({ id: item.id }));
        },
      };
      return formatedData;
    });
    return dataToShow;
  };

  useEffect(() => {
    fetchPaymentHistories(initialPageNo, formData.searchText);
  }, [appliedFilters]);

  useEffect(() => {
    dispatch(fetchPaymentFiltersRequest());
    return () => {
      dispatch(clearAppliedFilters());
    };
  }, []);

  return {
    formData,
    setFormData,
    errors,
    setErrors,
    onSubmit,
    paymentHistoryHeaders,
    paymentHistories: formatTableData(paymentHistories),
    pagination,
    lastRefreshedAt,
    fetchPaymentHistories,
    clearSearchText,
    exportCsv: () => {
      dispatch(exportCsvRequest());
    },
    showFilters,
    setShowFilters,
    clearAllFilters: () => {
      dispatch(clearAppliedFilters());
    },
    filtersCount: appliedFiltersCount,
  };
};

export default usePaymentsViewController;
