import { showToaster } from 'common/toasterActions';
import {
  fetchAllUsersOfTheDepartment,
  createUser,
  upadteUser,
  activateOrDeactivateUser,
  exportUsersAdminCsv,
} from 'services/admin';
import { resetDataForDoubleAuthetication } from '../../../components/MultiAuthenticatedRequest/actions';

import {
  FETCH_USERS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
  UPDATE_CREATE_USER_ADMIN_VALUE_IN_STORE,
  SAVE_ADD_USER_FORM_DATA,
  RESET_ADD_USER_DATA,
  SET_SELECTED_USER_ID,
  UPDATE_USERS_DATA_AFTER_SORTING,
} from './constants';

const fetchUsersListOnAdminPageRequestSucceeded = data => {
  return {
    type: FETCH_USERS_LIST_ON_ADMIN_PAGE_REQUEST_SUCCEED,
    data: {
      usersHeaders: data.userAttributes,
      usersData: data.users,
      pagination: data.pagination,
    },
  };
};

export const updateUserAdminValue = (key, value) => {
  return {
    type: UPDATE_CREATE_USER_ADMIN_VALUE_IN_STORE,
    key,
    value,
  };
};

export const saveAddUserFormData = data => {
  return {
    type: SAVE_ADD_USER_FORM_DATA,
    data,
  };
};

export const resetAddUsersData = data => {
  return {
    data,
    type: RESET_ADD_USER_DATA,
  };
};

export const setSelectedUserIdRequest = (id, userData) => {
  return {
    type: SET_SELECTED_USER_ID,
    data: { id, userData },
  };
};

export const updateUsersData = data => {
  return {
    type: UPDATE_USERS_DATA_AFTER_SORTING,
    data,
  };
};

export const createUserRequest = params => dispatch => {
  createUser(params)
    .then(response => {
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {});
};

export const updateUserRequest = params => dispatch => {
  upadteUser(params)
    .then(response => {
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {});
};

export const fetchUsersListOnAdminPageRequest = params => dispatch => {
  fetchAllUsersOfTheDepartment(params)
    .then(response => {
      dispatch(fetchUsersListOnAdminPageRequestSucceeded(response.data));
    })
    .catch(() => {});
};

export const activateOrDeactivateUserRequest = params => dispatch => {
  activateOrDeactivateUser(params)
    .then(response => {
      dispatch(resetDataForDoubleAuthetication());
      dispatch(showToaster({ message: response.data.message }));
    })
    .catch(() => {});
};

export const exportUsersCsvRequest = params => dispatch => {
  exportUsersAdminCsv(params)
    .then(response => {
      dispatch(resetDataForDoubleAuthetication());
      window.open(response.data.fileUrl);
    })
    .catch(() => {});
};
