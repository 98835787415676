import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import * as StorageService from 'services/storage';
import { USER_TYPE_PATIENT } from '../constants';

import history from '../configureHistory';

export const defaultLandingPageRouteGenerator = (location = null) => {
  const currentUser = StorageService.getCurrentUser();
  const searchParams = new URLSearchParams(window.location.search);
  const platform = searchParams.get('platform') || searchParams.get('Platform');
  let redirectLocation = null;
  let userType = null;
  if (currentUser) {
    userType = currentUser.type;
    // PassiveModeCheck
    if (currentUser && !isEmpty(history.location.search)) {
      redirectLocation = queryString.parse(
        history.location.search
      ).redirect_uri;
      if (redirectLocation) {
        if (currentUser.needsPasswordChange) {
          return `/reset-password?redirect_uri=${redirectLocation}`;
        }

        if (redirectLocation.includes('reports')) {
          return '/';
        }
        return redirectLocation;
      }
    }
  } else if (StorageService.isWebContainerRequest()) {
    return `/not-found?platform=${platform}`;
  } else if (location && location.pathname !== '/') {
    return {
      pathname: '/signin',
      search: `?redirect_uri=${location.pathname}`,
      hash: location.hash,
    };
  } else {
    return { pathname: '/signin' };
  }

  if (currentUser && currentUser.needsPasswordChange) {
    return '/reset-password';
  }

  switch (userType) {
    case USER_TYPE_PATIENT:
      if (StorageService.getActiveCompositeProcedureId()) {
        return `/mydashboard/${StorageService.getActiveCompositeProcedureId()}/procedures/appointments`;
      }
      return '/select-procedure';
    default:
      return '/';
  }
};

export const redirectToMainPage = () =>
  history.replace(defaultLandingPageRouteGenerator());

// Note this will just redirect to sign in page and does not logs out user. If user is already logged in then even after
// calling this, app will redirect to user to respective landing page.
export const redirectToSignIn = () => history.replace('/signin');

export const redirectToLogout = () => history.replace('/logout');

export const redirectToSelectPatient = () => {
  history.replace('/select-patient');
};

export const isValidatedUsersInitialProcessCompleted = location => {
  const currentUser = StorageService.getCurrentUser();
  let userType = null;
  if (currentUser) {
    userType = currentUser.type;
  } else {
    return false;
  }

  if (currentUser && currentUser.isPassiveMode) {
    return true;
  }

  if (
    currentUser &&
    currentUser.needsPasswordChange &&
    currentUser.signInCount > 0
  ) {
    if (location.pathname === '/reset-password') {
      return true;
    }
    return false;
  }

  if (
    location.pathname === '/profile' ||
    location.pathname === '/change-password' ||
    location.pathname === '/post-complete-procedure'
  ) {
    return true;
  }
  switch (userType) {
    case USER_TYPE_PATIENT:
      if (StorageService.getActiveCompositeProcedureId()) {
        return true;
      }
      if (location.pathname === '/select-procedure') {
        return true;
      }
      return false;
    default:
      return true;
  }
};
