import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import ConsentCardBucket from 'containers/Consent/consentCardBucket';
import './consent.scss';
import TabContentWrapper from 'containers/PatientDetails/Components/TabContentWrapper';
import { fetchConsentRequest } from './action';

const Consent = props => {
  const dispatch = useDispatch();
  const params = useParams();

  const [patientId, procedureId] = [
    parseInt(params.patientId, 10) || -1,
    parseInt(params.procedureId, 10) || -1,
  ];

  useEffect(() => {
    if (props.isActive)
      dispatch(
        fetchConsentRequest({
          patient_id: patientId,
          user_procedure_id: procedureId,
        })
      );
  }, [props.isActive]);

  const consentData = useSelector(store => store.consentReducer.data);

  const getConsentsCount = consentsArr => {
    let count = 0;
    Object.keys(consentsArr).map(key => {
      if (consentsArr[key].constructor == Array)
        count += consentsArr[key].length;
      return count;
    });
    return count;
  };

  return (
    <TabContentWrapper
      header={
        <div className='consent-tab-header'>
          {getConsentsCount(consentData) > 0 ? (
            <div className='consent-count'>
              {getConsentsCount(consentData) == 1
                ? '1 Consent'
                : `${getConsentsCount(consentData)} Consents`}
            </div>
          ) : (
            <></>
          )}
        </div>
      }
      body={
        <div>
          {Object.keys(consentData).map(key =>
            consentData[key].length ? (
              <ConsentCardBucket
                key={key}
                title={key}
                consents={consentData[key]}
              />
            ) : (
              <></>
            )
          )}
          <div className='border-line' />
        </div>
      }
    />
  );
};

export default Consent;
