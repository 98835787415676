import axios from './base';
import {
  GET_ALL_USERS_OF_THE_DEPARTMENT,
  GET_ALL_PROCEDURES_OF_THE_DEPARTMENT,
  FETCH_ALL_PROVIDERS,
  CREATE_USER,
  GET_DEPARTMENTS,
  FETCH_LOCATIONS,
  FETCH_DROPDOWN_OPTIONS,
  UPDATE_USER,
  FETCH_ALL_LOCACTIONS_FOR_ADMIN,
  FETCH_TIMEZONES,
  FETCH_PROVIDERS_NOT_PI,
  FETCH_ADVOCATES,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  ACTIVATE_OR_DEACTIVATE_USER,
  FETCH_ALL_CONSENTS,
  GET_LOCATION_CODES,
  FETCH_ALL_TASKS,
  GET_EVENT_KINDS,
  CREATE_TASK,
  UPDATE_TASK,
  FETCH_TASK_IDENTIFIERS,
  CREATE_TASK_REMINDER,
  CREATE_NOTIFICATION,
  DOWNLAD_CONSENT_TEMPLATE,
  CREATE_CONSENT,
  UPDATE_CONSENT,
  FETCH_ALL_RESOURCES,
  CREATE_RESOURCE,
  UPDATE_RESOURCE,
  FETCH_RESOURCE_CATEGORIES,
  FETCH_RESOURCE_ACTIONS,
  VALIDATE_UNIQ_LOCATION_CODE,
  VALIDATE_UNIQ_VERSION_FOR_CONSENT,
  FETCH_ADMIN_AUDIT_TRAIL,
  USER_ADMIN_EXPORT_CSV,
} from './constants';

export const fetchAllUsersOfTheDepartment = params =>
  axios.get(GET_ALL_USERS_OF_THE_DEPARTMENT, { params });

export const fetchProceduresOfTheDepartment = () =>
  axios.get(GET_ALL_PROCEDURES_OF_THE_DEPARTMENT);

export const fetchAllProviders = () => axios.get(FETCH_ALL_PROVIDERS);

export const createUser = params => axios.post(CREATE_USER, params);

export const upadteUser = params => axios.put(UPDATE_USER, params);

export const fetchAllDepartments = () => axios.get(GET_DEPARTMENTS);

export const fetchAllLocations = () => axios.get(FETCH_LOCATIONS);

export const fetchCreateUserDropdownOptions = () =>
  axios.get(FETCH_DROPDOWN_OPTIONS);

export const fetchAllLocationsOfTheApplication = params =>
  axios.get(FETCH_ALL_LOCACTIONS_FOR_ADMIN, { params });

export const fetchAdminAuditTrail = () => axios.get(FETCH_ADMIN_AUDIT_TRAIL);

export const fetchTimezones = () => axios.get(FETCH_TIMEZONES);

export const fetchProviderNotPi = () => axios.get(FETCH_PROVIDERS_NOT_PI);

export const fetchAdvocates = () => axios.get(FETCH_ADVOCATES);

export const createLocation = params => axios.post(CREATE_LOCATION, params);

export const updateLocation = params => axios.put(UPDATE_LOCATION, params);

export const activateOrDeactivateUser = params =>
  axios.post(ACTIVATE_OR_DEACTIVATE_USER, params);

export const exportUsersAdminCsv = params =>
  axios.post(USER_ADMIN_EXPORT_CSV, params);

export const fetchAllTasksOfTheApplication = params =>
  axios.get(FETCH_ALL_TASKS, params);

export const fetchAllConsentsOfTheApplication = params =>
  axios.get(FETCH_ALL_CONSENTS, { params });

export const getLocationCodes = params => axios.get(GET_LOCATION_CODES, params);

export const getEventKinds = params => axios.get(GET_EVENT_KINDS, params);

export const createTask = params => axios.post(CREATE_TASK, params);

export const updateTask = params => axios.post(UPDATE_TASK, params);

export const fetchTaskIdentifiers = params =>
  axios.get(FETCH_TASK_IDENTIFIERS, { params });

export const createTaskReminder = params =>
  axios.post(CREATE_TASK_REMINDER, params);

export const createNotification = params =>
  axios.post(CREATE_NOTIFICATION, params);

export const downloadTemplateOfConsent = params =>
  axios.get(DOWNLAD_CONSENT_TEMPLATE, { params });

export const createConsent = params => axios.post(CREATE_CONSENT, params);

export const updateConsent = params =>
  axios.put(`${UPDATE_CONSENT}/${params.id}`, params);

export const fetchAllResourcesOfTheDepartment = params =>
  axios.get(FETCH_ALL_RESOURCES, { params });

export const createResource = params => axios.post(CREATE_RESOURCE, params);

export const upadteResource = params =>
  axios.put(`${UPDATE_RESOURCE}/${params.id}`, params);

export const fetchResourceCategories = params =>
  axios.get(FETCH_RESOURCE_CATEGORIES, params);

export const fetchResourceActions = params =>
  axios.get(FETCH_RESOURCE_ACTIONS, params);

export const validateForUniqLocationCode = params =>
  axios.post(VALIDATE_UNIQ_LOCATION_CODE, params);

export const validateForUniqueConsentVersion = params =>
  axios.post(VALIDATE_UNIQ_VERSION_FOR_CONSENT, params);
