import { AppAction } from 'types';
import * as Constants from './constants';
import {
  FiltersResponseType,
  PaymentsDataObjectType,
  ResponseDataType,
  PaymentsReducerState,
  AppliedFilter,
} from './types';

type ActionType = AppAction & {
  data:
    | ResponseDataType
    | PaymentsDataObjectType
    | FiltersResponseType
    | AppliedFilter;
};

const initialState = <PaymentsReducerState>{
  paymentHistoryHeaders: [],
  paymentHistories: [],
  pagination: {
    endNumber: null,
    firstPage: null,
    lastPage: null,
    page: null,
    prevPage: null,
    nextPage: null,
    startNumber: null,
    total: null,
    totalPages: null,
  },
  lastRefreshedAt: '',
  filters: [],
  appliedFilters: {},
  appliedFiltersCount: 0,
};

const paymentsReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case Constants.FETCH_PAYMENT_HISTORIES_REQUEST_SUCCEED: {
      const data = action.data as ResponseDataType;
      return {
        ...state,
        paymentHistoryHeaders: data.paymentHistoryHeaders,
        paymentHistories: data.paymentHistories,
        pagination: { ...state.pagination, ...data.pagination },
        lastRefreshedAt: data.lastRefreshedAt,
      };
    }

    case Constants.REFRESH_PAYMENT_HISTORY_REQUEST_SUCCEED: {
      const data = action.data as PaymentsDataObjectType;
      return {
        ...state,
        paymentHistories: state.paymentHistories.map(
          (item: PaymentsDataObjectType) => {
            if (item.id === data.id) return data;
            return item;
          }
        ),
      };
    }

    case Constants.FETCH_PAYMENT_FILTERS_SUCCEED: {
      const data = action.data as FiltersResponseType;
      return {
        ...state,
        filters: data.filters,
      };
    }

    case Constants.SET_APPLIED_FILTERS: {
      const data = action.data as AppliedFilter;
      return {
        ...state,
        appliedFilters: data,
        appliedFiltersCount: Object.values(data).reduce(
          (acc, currArray) => acc + currArray.length,
          0
        ),
      };
    }

    case Constants.CLEAR_APPLIED_FILTERS: {
      return {
        ...state,
        appliedFilters: {},
        appliedFiltersCount: 0,
      };
    }

    default:
      return state;
  }
};

export default paymentsReducer;
