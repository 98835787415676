/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Pagination from 'components/Pagination';
import { useAppDispatch } from 'hooks';
import { PaginationType } from 'types';
import { secureActionWithDoubleAuthentication } from 'components/MultiAuthenticatedRequest/actions';
import { SecureCodes } from 'components/MultiAuthenticatedRequest/securedActionCodes';
import { downloadReportAction } from '../actions';
import { ReportDataType } from '../types';

interface ReportTableType {
  data: ReportDataType[];
  title: string;
  // eslint-disable-next-line react/require-default-props
  pagination?: PaginationType | null;
  // eslint-disable-next-line react/require-default-props
  onPageChange?: (pageNumber: number) => void;
}
const ReportTable: React.FC<ReportTableType> = ({
  data,
  title,
  onPageChange,
  pagination = null,
}) => {
  const dispatch = useAppDispatch();
  return (
    <div className='report-table-wrapper'>
      <p>{title}</p>
      {!data[0]?.id ? (
        <p className='no-report-message'>No {title}</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Download</th>
              <th>Generated On</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td className='download-icon-wrapper'>
                  {' '}
                  <span
                    onClick={() => {
                      dispatch(
                        secureActionWithDoubleAuthentication(
                          downloadReportAction,
                          {
                            id: item.id?.toString(),
                            needs_reason: true,
                            secure_action_code:
                              SecureCodes.DOWNLOAD_DATA_REPORT,
                          }
                        )
                      );
                    }}
                    className='icon icon-download download-click'
                  />
                </td>
                <td>{item.generatedOn}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {pagination ? (
        <div className='pagination-wrapper'>
          <Pagination
            pagination={pagination}
            title='Pages'
            handlePagination={changedPage => {
              if (onPageChange) onPageChange(changedPage);
            }}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default ReportTable;
