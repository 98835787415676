import axios from './base';
import {
  GET_PATIENT_DETAILS,
  GET_PATIENT_TASKS,
  GET_OPTIONAL_TASKS,
  ASSIGN_OPTIONAL_TASKS,
  GET_PATIENT_QUESTIONS,
  GET_PATIENT_APPOINTMENTS,
  GET_TELEMEDICINE_APPOINTMENT_ACCESS_TOKEN,
  GET_PATIENT_NOTIFICATIONS,
  POST_PROVIDER_CREATE_TASK,
  GET_PROCEDURE_LOCATIONS,
  PUT_UPDATE_EVENT,
  DELETE_TASK,
  TASK_DETAILS,
  DELETE_ALL_RECURRING_TASKS,
  DELETE_PATIENT,
  POST_CREATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  SKIP_APPOINTMENT,
  UPDATE_PATIENT_NEEDS_ATTENTION,
  GET_PATIENT_NOT_MOVING_FORWARD_REASONS,
  UPDATE_NOT_MOVING_FORWARD,
  POST_MARK_TASK_COMPLETE,
  POST_SUBMIT_SURVEY_FEEDBACK,
  RESEND_INVITE,
  UPDATE_PATIENT_INFO,
  GET_PROCEDURE_MANAGERS,
  ADD_PROCEDURE_MANAGERS,
  REMOVE_PROCEDURE_MANAGERS,
  POST_DISCARD_PROCEDURE,
  PUT_TERMINATE_TRIAL,
  GET_PATIENT_CONVERSATIONS,
  GET_CONVERSATION_MESSAGES,
  START_CONVERSATION,
  RESOLVE_CONVERSATION,
  SEND_MESSAGE,
  GET_LOCATION_PROVIDERS,
  GENERATE_OTP,
  VERIFY_OTP,
  CREATE_PATIENT,
  SAVE_PROCEDURE,
  GET_FLOW_ID,
  GET_MODIFIER,
  GET_FACILITY,
  TRACK_PATIENT_AS_VIEWED,
  UPDATE_SALESFORCE_ACCOUNT_INFO_CHECKBOX,
  UPDATE_APPOINTMENT_NOTES,
  FETCH_CONSENTS,
  SET_SELECTED_PHASE,
  EXIT_PATIENT_FROM_STUDY,
  DOWNLOAD_SIGNED_CONSENTS,
  CONSENT_RESEND_INVITE,
  GET_DIARY_ENTRIES,
  MARK_DIARY_READ,
  UPLOAD_PAPER_CONSENT,
  FETCH_AUDUIT_TRAILS,
  DOWNLOAD_AUDIT_TRAIL_CSV,
  FETCH_CONSENTS_FOR_AUDIT_TRAIL,
  DOWNLOAD_CONSENT_AUDIT_TRAIL,
  REVOKE_ACCOUNT_DELETION,
  DOWNLOAD_CONSENT_FORM,
  UPDATE_DIARY,
  FETCH_DEVICE_INTERROGATION_OPTIONS,
  COMPLETE_DEVICE_INTERROGATION,
} from './constants';
import { currentTimezoneName } from '../utils/dateUtils';

export const getPatientDetails = (id, userProcedureId) => {
  const params = {
    user_procedure_id: userProcedureId,
  };
  return axios.get(`${GET_PATIENT_DETAILS}${id}`, { params });
};

export const trackPatientAsViewed = (id, userProcedureId) => {
  const url = TRACK_PATIENT_AS_VIEWED.replace(':id', id);
  const params = {
    user_procedure_id: userProcedureId,
  };
  return axios.post(url, params);
};

export const getPatientTasks = (
  id,
  userProcedureId,
  showAll,
  procedurePhaseId = null
) => {
  const params = {
    patient_id: id,
    user_procedure_id: userProcedureId,
    show_all: showAll ? 1 : 0,
  };

  if (procedurePhaseId) {
    params.procedure_phase_id = procedurePhaseId;
  }
  return axios.get(GET_PATIENT_TASKS, {
    params,
  });
};

export const getOptionalTasks = userProcedureId => {
  const params = {
    user_procedure_id: userProcedureId,
  };

  return axios.get(GET_OPTIONAL_TASKS, {
    params,
  });
};

export const assignTasks = (patientId, userProcedureId, data, password) => {
  const reqBody = data;
  reqBody.patient_id = patientId;
  reqBody.user_procedure_id = userProcedureId;
  reqBody.current_password = password;
  return axios.post(ASSIGN_OPTIONAL_TASKS, reqBody);
};

export const getPatientQuestions = (
  id,
  userProcedureId,
  procedurePhaseId = null
) => {
  const params = {
    patient_id: id,
    user_procedure_id: userProcedureId,
  };
  if (procedurePhaseId) {
    params.procedure_phase_id = procedurePhaseId;
  }
  return axios.get(GET_PATIENT_QUESTIONS, {
    params,
  });
};

export const getPatientConversations = (
  compositeProcedureId,
  procedurePhaseId = null,
  page = null
) => {
  const params = {
    composite_procedure_id: compositeProcedureId,
  };
  if (procedurePhaseId) {
    params.procedure_phase_id = procedurePhaseId;
  }
  if (page) {
    params.page = page;
  }
  return axios.get(GET_PATIENT_CONVERSATIONS, {
    params,
  });
};

export const startConversation = params => {
  return axios.post(START_CONVERSATION, params);
};

export const resolveConversation = params =>
  axios.post(RESOLVE_CONVERSATION.replace(':id', params.resolveId), params);

export const postSendMessage = params => axios.post(SEND_MESSAGE, params);

export const getConversationMessages = conversationId =>
  axios.get(GET_CONVERSATION_MESSAGES.replace(':id', conversationId));

export const getPatientAppointments = (
  id,
  compositeProcedureId,
  procedurePhaseId
) => {
  const params = {
    patient_id: id,
    composite_procedure_id: compositeProcedureId,
    skip_survey_events: true,
  };

  if (procedurePhaseId) {
    params.procedure_phase_id = procedurePhaseId;
  }

  params.timezone = currentTimezoneName;
  return axios.get(GET_PATIENT_APPOINTMENTS, {
    params,
  });
};

export const getPatientNotifications = (
  id,
  userProcedureId,
  procedurePhaseId = null
) => {
  const params = {
    patient_id: id,
    user_procedure_id: userProcedureId,
  };
  if (procedurePhaseId) {
    params.procedure_phase_id = procedurePhaseId;
  }
  return axios.get(GET_PATIENT_NOTIFICATIONS, {
    params,
  });
};

export const getTelemedicineAppointmentAccessToken = roomSid =>
  axios.get(GET_TELEMEDICINE_APPOINTMENT_ACCESS_TOKEN.replace(':sid', roomSid));

export const sendTaskReminder = params =>
  axios.post(`/v2/tasks/${params.taskId}/remind`, params);

export const sendTeleCallReminder = (id, pId) =>
  axios.post(`/telemedicine_appointment_rooms/${id}/remind_participant/${pId}`);

export const createTask = (patientId, userProcedureId, data, password) => {
  const reqBody = data;
  reqBody.patient_id = patientId;
  reqBody.user_procedure_id = userProcedureId;
  reqBody.current_password = password;
  return axios.post(POST_PROVIDER_CREATE_TASK, reqBody);
};

export const getEventQuestions = eventId =>
  axios.get(`/v2/events/${eventId}/questions/`);

export const getLocationOptions = locationId => {
  const params = {
    id: locationId,
  };

  return axios
    .get(GET_PROCEDURE_LOCATIONS, {
      params,
    })
    .then(response => ({
      options: response.data.locations.map(item => ({
        value: item.id,
        label: item.name,
      })),
    }));
};

export const getLocationProviders = (locationCode, procedureCode) => {
  const params = {
    location_code: locationCode,
    procedure_code: procedureCode,
  };
  return axios
    .get(GET_LOCATION_PROVIDERS, {
      params,
    })
    .then(response => ({
      providers: response.data.providers.map(item => ({
        value: item.id,
        label: item.fullNameWithSalutation,
      })),
      procedure: response.data.procedure,
      // generalCardiologists: response.data.generalCardiologists,
      generalCardiologists: response.data.generalCardiologists.map(item => ({
        value: item.id,
        label: item.fullName,
      })),
      isMfaEnabled: response.data.isMfaEnabled,
    }))
    .catch(() => {});
};

export const getFlowId = params => {
  return axios.get(GET_FLOW_ID, { params });
};

export const getModifierApiCall = params => {
  return axios.get(GET_MODIFIER, { params });
};

export const getFacilityApiCall = params => {
  return axios.get(GET_FACILITY, { params });
};

export const generateOtp = paramsInArgs => {
  const params = paramsInArgs;
  if (params.contact_number) {
    params.contact_number = params.contact_number
      .replaceAll(' ', '')
      .replace('+1', '');
  }

  return axios.post(GENERATE_OTP, params);
};

export const verifyOtp = paramsInArgs => {
  const params = paramsInArgs;
  if (params.contact_number) {
    params.contact_number = params.contact_number
      .replaceAll(' ', '')
      .replace('+1', '');
  }

  return axios.post(VERIFY_OTP, params);
};

export const createPatient = patient => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return axios.post(CREATE_PATIENT, patient, { headers });
};

export const saveProcedure = procedure => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return axios.post(SAVE_PROCEDURE, procedure, { headers });
};

export const updateAppointment = params => {
  return axios.put(`${PUT_UPDATE_EVENT}${params.appointmentId}`, params);
};

export const deleteSelectedTask = params =>
  axios.delete(DELETE_TASK, {
    params: {
      task_id: params.taskId,
      current_password: params.current_password,
    },
  });

export const getPatientTaskDetails = (taskId, patientId) => {
  if (patientId) {
    const params = {
      patient_id: patientId,
    };
    return axios.get(`${TASK_DETAILS}${taskId}`, { params });
  }
  return axios.get(`${TASK_DETAILS}${taskId}`);
};

export const getReminderHistory = userTaskId =>
  axios.get(`v2/tasks/${userTaskId}/reminder_history`);

export const deleateAllRecurringTasksInstance = (userTaskId, password) =>
  axios.delete(DELETE_ALL_RECURRING_TASKS, {
    params: { user_task_id: userTaskId, current_password: password },
  });

export const removePatient = params => {
  const url = DELETE_PATIENT.replace(':id', params.id);
  return axios.delete(url, { params });
};

export const createAppointment = params =>
  axios.post(POST_CREATE_APPOINTMENT, params);

export const deleteAppointment = params => {
  const url = DELETE_APPOINTMENT.replace(':id', params.appointment_id);
  return axios.delete(url, { data: params });
};

export const skipAppointment = (eventId, password) => {
  const url = SKIP_APPOINTMENT.replace(':id', eventId);
  return axios.post(url, { current_password: password });
};

export const updateNeedsAttention = params =>
  axios.post(UPDATE_PATIENT_NEEDS_ATTENTION, params);

export const getPatientNotMovingForwardReasons = params =>
  axios.get(GET_PATIENT_NOT_MOVING_FORWARD_REASONS, { params });

export const updateNotMovingForward = params => {
  const url = UPDATE_NOT_MOVING_FORWARD.replace(':id', params.id);
  return axios.post(url, params);
};

export const postMarkTaskCompleteService = reqObj =>
  axios.post(POST_MARK_TASK_COMPLETE, reqObj);

export const getSurveyFeedback = (eventId, patientId) => {
  const params = {
    patient_id: patientId,
  };
  return axios.get(`/v2/events/${eventId}/expected_questions`, { params });
};

export const submitSuveryFeedback = params =>
  axios.post(POST_SUBMIT_SURVEY_FEEDBACK, params);

export const resendInvite = params => {
  const url = RESEND_INVITE.replace(':id', params.id);
  return axios.post(url, params);
};

export const updatePatientInfo = params => {
  const url = UPDATE_PATIENT_INFO.replace(':id', params.id);
  return axios.put(url, { ...params });
};

export const updateSalesforceCheckbox = params => {
  const url = UPDATE_SALESFORCE_ACCOUNT_INFO_CHECKBOX.replace(
    ':user_procedure_id',
    params
  );
  return axios.put(url);
};
export const getProcedureManagers = params =>
  axios.get(GET_PROCEDURE_MANAGERS, { params });

export const addProcedureManagers = params =>
  axios.post(ADD_PROCEDURE_MANAGERS, params);

export const removeProcedureManagers = params => {
  const url = REMOVE_PROCEDURE_MANAGERS.replace(':id', params.id);
  return axios.delete(url, { params });
};

export const discardUserProcedure = params =>
  axios.post(POST_DISCARD_PROCEDURE, params);

export const terminateProcedureTrial = userProcedureId =>
  axios.put(PUT_TERMINATE_TRIAL, { user_procedure_id: userProcedureId });

export const updateAppointmentNotes = params => {
  const url = UPDATE_APPOINTMENT_NOTES.replace(':id', params.appointmentId);
  return axios.put(url, params);
};

export const fetchConsents = params => axios.get(FETCH_CONSENTS, params);

export const fetchAuditTrails = params =>
  axios.get(FETCH_AUDUIT_TRAILS.replace(':id', params.patient_id), { params });

export const setSelectedPhase = params => {
  const url = SET_SELECTED_PHASE.replace(':user_id', params.id).replace(
    ':user_procedure_id',
    params.user_procedure_id
  );

  return axios.post(url, params);
};

export const exitPatientFromStudy = data => {
  const params = {
    id: data.userId,
    user_procedure_id: data.userProcedureId,
    not_moving_forward: data.notMovingForward,
    reason: data.notMovingForwardReasonId,
    additional_comment: data.notMovingForwardAdditionalComment,
    current_password: data.currentPassword,
    reason_audit_trail: data.reasonAuditTrail,
  };
  const url = EXIT_PATIENT_FROM_STUDY.replace(':user_id', data.userId);
  return axios.post(url, params);
};

export const downloadSignedConsents = params =>
  axios.get(DOWNLOAD_SIGNED_CONSENTS, { params });
export const downloadUninitiatedConsents = params =>
  axios.get(DOWNLOAD_CONSENT_FORM, { params });

export const consentResendInvite = params => {
  return axios.post(CONSENT_RESEND_INVITE, params);
};
export const getAllDiaryEntries = patientId => {
  const params = {
    patient_id: patientId,
  };
  return axios.get(GET_DIARY_ENTRIES, { params });
};

export const markDiariesAsRead = patientId => {
  const params = {
    patient_id: patientId,
  };
  return axios.post(MARK_DIARY_READ, params);
};
export const updateDiaryEntry = params =>
  axios.put(UPDATE_DIARY.replace(':id', params.id), params);

export const uploadPaperConsent = params =>
  axios.post(UPLOAD_PAPER_CONSENT, params);

export const downloadCSV = params =>
  axios.get(DOWNLOAD_AUDIT_TRAIL_CSV.replace(':id', params.id));

export const fetchConsentsForAuditTrail = params =>
  axios.get(FETCH_CONSENTS_FOR_AUDIT_TRAIL, { params });

export const downloadConsentAuditTrailData = params =>
  axios.get(DOWNLOAD_CONSENT_AUDIT_TRAIL, { params });

export const revokeAccountDeletion = params =>
  axios.post(REVOKE_ACCOUNT_DELETION, params);

export const fetchDeviceInterrogationOptions = params =>
  axios.get(
    FETCH_DEVICE_INTERROGATION_OPTIONS.replace(
      ':user_procedure_id',
      params.user_procedure_id
    )
  );

export const completeDeviceInterrogation = params =>
  axios.put(COMPLETE_DEVICE_INTERROGATION, params);
