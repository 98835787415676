/* eslint-disable */
/** \n 
@author Dilip Joshi
@desc React component for a form generator that allows to generate different types of inputs based on the form configurations.
@param {Object} props - Props for customizing the form group.
@param {Array} props.formConfig - An array of objects that contains configurations for every input field.
@param {string} props.id - Unique Id for the form.
@param {string} props.className - Custom class name for the form.
@param {Object} props.errors - An object containing the {key: value} pairs of error messages for every input field that has error.
@param {function} props.onMenuClose - Function that gets called when the dropdown in a SelectV2 field is closed
@param {Array} [props.buttons] - An array of JSX buttons to display below the form
@returns A form with inputs that can be customized as per configurations.


@example

[{
  id: 'company-email',
  input_type: 'input',
  type: 'email',
  name: 'email',
  value: 'test@test.com',
  label: 'Company Email',
  placeholder: 'Company email',
  dataSaveAction: 'emailChanged',
  visible: true,
  options: []
},
{
id: 'company-phone_number',
input_type: 'phone',
type: '',
name: 'phone_number',
value: '',
label: 'Phone Number*',
placeholder: 'Enter phone number',
dataSaveAction: 'phoneNumberChanged',
visible: true,
options: []
},
{
id: 'callers-input-field',
input_type: 'multi_select',
name: 'callersInputField',
value: '',
label: 'Select callers',
placeholder: 'Enter callers',
visible: true,
options: [{ value: 'test1', label: 'Test 1' }, { value: 'test2', label: 'Test 2' }]
},
{
id: 'status-input-field',
input_type: 'single_select',
name: 'statusInputField',
value: '',
label: 'Select status',
placeholder: 'Enter status',
visible: true,
options: [{ value: 'test1', label: 'Test 1' }, { value: 'test2', label: 'Test 2' }]
}
];
 */

import React from 'react';
import { HelpBlock } from 'react-bootstrap';
import PhoneInput from 'components/PhoneInput';
import SingleSelectWithBorder, {
  MultiSelectForPill,
} from 'components/SelectV2';
import MaskedInput from 'components/StyleGuideComponents/MaskedInput';
import AddAttachmentModal from 'containers/AddAttachmentModal';
import SelectDropdown from 'components/StyleGuideComponents/SelectDropdown';
import InputTextField from 'components/StyleGuideComponents/InputTextField';
import Password from '../StyleGuideComponents/Password';
import FormHelperText from '@mui/material/FormHelperText';

import './form_generator.scss';

const AddAttachment = props => {
  return (
    <div className={`form-group ${props.error ? 'has-error' : ''}`}>
      <AddAttachmentModal
        onUpdateAttachments={mediaResources =>
          props.onChange(props.name, mediaResources)
        }
        showModal={props.visible}
        onModalClose={props.onAttachmentClose}
        isBindedWithModal={props.isBindedWithModal}
        fileFormats={props.fileFormats}
        maxAttachmentCount={props.maxAttachmentCount}
        mediaResources={props.value}
        isFrom={props.isFrom}
      />
      <HelpBlock value={props.error}>{props.error}</HelpBlock>
    </div>
  );
};

const MuiPassword = props => {
  return props.visible ? (
    <div className={`form-group ${props.error ? 'has-error' : ''}`}>
      {props.label && !props.isInlineLabelNeeded ? (
        <label
          htmlFor={props.name}
          className={`form-label ${props.required ? 'required' : ''}`}
        >
          {props.label}
        </label>
      ) : (
        <></>
      )}
      <Password
        id={props.id}
        label={props.label || 'Enter Password'}
        autoComplete={props.autoComplete}
        name={props.name || 'password'}
        value={props.value}
        onChange={(name, value) => props.onChange(name, value)}
        required={props.required}
        error={props.error}
        errorText={props.error}
        placeholder={props.placeholder || 'Enter Password'}
        isInlineLabelNeeded={props.isInlineLabelNeeded}
      />
    </div>
  ) : (
    <></>
  );
};

const MuiSingleSelect = props => {
  return (
    <div className={`form-group ${props.error ? 'has-error' : ''}`}>
      {props.label && !props.isInlineLabelNeeded ? (
        <label
          htmlFor={props.name}
          className={`form-label ${props.required ? 'required' : ''}`}
        >
          {props.label}
        </label>
      ) : (
        <></>
      )}
      <SelectDropdown
        id={props.id}
        label={props.label}
        error={props.error}
        errorText={props.error}
        options={props.options}
        handleChange={e => {
          props.onChange(props.name, e.target.value);
        }}
        value={props.value}
        defaultValue={props.value}
        isInlineLabelNeeded={props.isInlineLabelNeeded}
        placeholder={props.placeholder}
      />
    </div>
  );
};

const InputWithMask = props => {
  return (
    <div className={`form-group ${props.error ? 'has-error' : ''}`}>
      {props.label ? (
        <label
          htmlFor={props.name}
          className={`form-label ${props.required ? 'required' : ''}`}
        >
          {props.label}
        </label>
      ) : (
        <></>
      )}
      <MaskedInput
        id={props.id}
        className='form-control'
        type={props.type}
        placeholder={props.placeholder}
        name={props.name}
        onChange={e => props.onChange(e.target.name, e.target.value)}
        value={props.value}
        disabled={props.disabled}
        mask={props.mask}
      />
      <HelpBlock value={props.error}>{props.error}</HelpBlock>
    </div>
  );
};

const TextArea = props => {
  return props.visible ? (
    <div className={`form-group ${props.error ? 'has-error' : ''}`}>
      {props.label ? (
        <label
          htmlFor={props.name}
          className={`form-label ${props.required ? 'required' : ''}`}
        >
          {props.label}
        </label>
      ) : (
        <></>
      )}
      <textarea
        id={props.id}
        className='form-control'
        type='text'
        maxLength={props.maxLength || 999999}
        placeholder={props.placeholder}
        name={props.name}
        onChange={e => props.onChange(e.target.name, e.target.value)}
        value={props.value}
        disabled={props.disabled}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>
          {' '}
          <HelpBlock value={props.error}>{props.error}</HelpBlock>
        </span>
        {props.maxLength ? (
          <span className='character-limit'>
            {`${props.value.length} / ${props.maxLength}`}
          </span>
        ) : (
          ''
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

const CheckBox = props => {
  return props.visible ? (
    <div className={`inline-form ${props.error ? 'has-error' : ''}`}>
      <input
        type='checkbox'
        className='form-control'
        disabled={props.disabled}
        name={props.name}
        checked={props.value}
        onChange={e => {
          props.onChange(props.name, e.target.checked);
        }}
      />
      {props.label ? <label htmlFor={props.name}>{props.label}</label> : <></>}
    </div>
  ) : (
    <></>
  );
};

const Input = props => {
  return props.visible ? (
    <div className={`form-group ${props.error ? 'has-error' : ''}`}>
      {props.label ? (
        <label
          htmlFor={props.name}
          className={`form-label ${props.required ? 'required' : ''}`}
        >
          {props.label}
        </label>
      ) : (
        <></>
      )}
      <input
        id={props.id}
        className='form-control'
        type={props.type}
        placeholder={props.placeholder}
        name={props.name}
        onChange={e => props.onChange(e.target.name, e.target.value)}
        value={props.value}
        disabled={props.disabled}
        min={props.min}
        step={props.step}
      />
      <HelpBlock value={props.error}>{props.error}</HelpBlock>
    </div>
  ) : (
    <></>
  );
};

const Phone = props => {
  return (
    <div className={`form-group ${props.error ? 'has-error' : ''}`}>
      {props.label ? (
        <label
          htmlFor={props.name}
          className={`form-label ${props.required ? 'required' : ''}`}
        >
          {props.label}
        </label>
      ) : (
        <></>
      )}
      <PhoneInput
        id={props.id}
        className='form-control'
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        mask={props.mask}
        onChange={e => props.onChange(e.target.name, e.target.value)}
        disabled={props.disabled}
      />
      <HelpBlock value={props.error}>{props.error}</HelpBlock>
    </div>
  );
};

const SingleSelect = props => {
  return (
    <div className={`form-group ${props.error ? 'has-error' : ''}`}>
      {props.label ? (
        <label
          htmlFor={props.name}
          className={`form-label ${props.required ? 'required' : ''}`}
        >
          {props.label}
        </label>
      ) : (
        <></>
      )}
      <SingleSelectWithBorder
        id={props.id}
        placeholder={props.placeholder}
        name={props.name}
        disabled={props.disabled}
        onChange={e => {
          props.onChange(props.name, e);
        }}
        value={props.value}
        options={props.options}
        isClearable={props.isClearable}
        isCreatable={props.isCreatable}
      />
      <HelpBlock value={props.error}>{props.error}</HelpBlock>
    </div>
  );
};

const MultiSelect = props => {
  return props.visible ? (
    <div className={`form-group ${props.error ? 'has-error' : ''}`}>
      <label
        htmlFor={props.name}
        className={`form-label ${props.required ? 'required' : ''}`}
      >
        {props.label}
      </label>
      <MultiSelectForPill
        placeholder={props.placeholder}
        isSearchable={props.isSearchable}
        isClearable={props.isClearable}
        isDisabled={props.disabled}
        readOnly
        isPill
        onChange={e => {
          props.onChange(props.name, e);
        }}
        onMenuClose={props.onMenuClose}
        options={props.options}
        value={props.value}
      />

      <HelpBlock>{props.error}</HelpBlock>
    </div>
  ) : (
    <></>
  );
};

const InputText = props => {
  return props.visible ? (
    <div className={`form-group ${props.error ? 'has-error' : ''}`}>
      <label
        htmlFor={props.name}
        className={`form-label ${props.required ? 'required' : ''}`}
      >
        {props.label}
      </label>
      <InputTextField
        id={props.id}
        label={props.isInlineLabelNeeded ? props.label : ''}
        name={props.name}
        value={props.value}
        onChange={(name, value) => {
          props.onChange(name, value);
        }}
        required={props.required}
        maxLength={props.maxLength || 100}
        placeholder={props.placeholder || ''}
        error={props.error}
        errorText={props.error}
      />
    </div>
  ) : (
    <></>
  );
};

const InputTextWithoutLabel = props => {
  return props.visible ? (
    <div className={`form-group ${props.error ? 'has-error' : ''}`}>
      <InputTextField
        id={props.id}
        label={props.isInlineLabelNeeded ? props.label : ''}
        name={props.name}
        value={props.value}
        onChange={(name, value) => {
          props.onChange(name, value);
        }}
        required={props.required}
        maxLength={props.maxLength || 100}
        placeholder={props.placeholder || ''}
        error={props.error}
        errorText={props.error}
      />
    </div>
  ) : (
    <></>
  );
};

const HelperText = props => {
  return props.visible ? (
    <FormHelperText className='no-margin text-align-center'>
      <span className='form_generator_helper_text'>
        {props.value ? props.value : ''}
      </span>
    </FormHelperText>
  ) : (
    <></>
  );
};

const FormGenerator = props => {
  const formElement = config => {
    switch (config.input_type) {
      case 'input':
        return Input;
      case 'masked_input':
        return InputWithMask;
      case 'phone':
        return Phone;
      case 'single_select':
        return SingleSelect;
      case 'multi_select':
        return MultiSelect;
      case 'checkbox':
        return CheckBox;
      case 'textarea':
        return TextArea;
      case 'attachment':
        return AddAttachment;
      case 'mui_password':
        return MuiPassword;
      case 'mui_single_select':
        return MuiSingleSelect;
      case 'input_text_field':
        return InputText;
      case 'input_text_field_without_label':
        return InputTextWithoutLabel;
      case 'helper_text':
        return HelperText;
      default:
        return <></>;
    }
  };

  return (
    <div className='form-wrapper'>
      <form
        className={props.className}
        id={props.id}
        onSubmit={e => e.preventDefault()}
      >
        {props.formConfig?.map(config => {
          const FormComponent = formElement(config);
          return (
            <FormComponent
              id={config.id}
              type={config.type}
              placeholder={config.placeholder}
              name={config.name}
              value={config.value}
              error={props.errors[config.name]}
              label={config.label}
              onChange={(key, value) => {
                props.onChange(key, value, config.onChangeCallBack);
              }}
              key={config.id}
              options={config.options}
              dataSaveAction={config.dataSaveAction}
              isSearchable={config.isSearchable}
              isClearable={config.isClearable}
              onMenuClose={props.onMenuClose ? props.onMenuClose : () => {}}
              visible={config.visible}
              disabled={config.disabled}
              maxLength={config.maxLength}
              mask={config.mask}
              onAttachmentClose={config.onAttachmentClose}
              isBindedWithModal={config.isBindedWithModal}
              maxAttachmentCount={config.maxAttachmentCount}
              fileFormats={config.fileFormats}
              isFrom={config.isFrom}
              required={config.required}
              isCreatable={config.isCreatable}
              min={config.min}
              step={config.step}
              autoComplete={config.autoComplete}
              isInlineLabelNeeded={config.isInlineLabelNeeded}
            />
          );
        })}
      </form>
      <div className='generated-form-buttons'>
        {props.buttons ? props.buttons.map(button => button) : <></>}
      </div>
    </div>
  );
};

export default FormGenerator;
